import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';


const http = createHttp(appConfig.base2GUrl2);

const URL = {
  getAdasAlarmToday: 'monitor/getAdasAlarmToday.do',
  getFCWAlarmToday: 'monitor/getAlarmToday.do',
  getAdasAttachment: 'monitor/getAdasAttachment.do',
};


/**
 * 监控中心查询当天Adas报警
 * @param {*} param.groupId 车组Id
 * @param {*} param.vehicleId 车辆Id
 * @param {*} param.alarmTypes 报警类型
 * @param {*} param.flag 0:车组；1：车辆
 */
export const getAdasAlarmToday = params => http.get(URL.getAdasAlarmToday, { params: { ...params, alarmTypes: params.alarmTypes.join(',') } });

/**
 * 监控中心查询当天常规预警
 * @param {*} param.groupId 车组Id
 * @param {*} param.vehicleId 车辆Id
 * @param {*} param.alarmTypes 报警类型
 * @param {*} param.flag 0:车组；1：车辆
 */
export const getFCWAlarmToday = params => http.get(URL.getFCWAlarmToday, { params: { ...params, alarmTypes: params.alarmTypes.join(',') } });

/**
 * 监控中心查询当天Adas报警附件
 * @param {*} param.type 报警类型
 * @param {*} param.vehicleId 车辆Id
 * @param {*} param.alarmId 报警id
 * @param {*} param.startTime 开始时间
 */
export const getAdasAttachment = params => http.get(URL.getAdasAttachment, { params });


export const downloadAlarmEvidence = params => http.get('alarmRecord/downloadAlarmEvidence.do',
  {
    params,
    responseType: 'blob'
  });
