<template>
  <div class="ShiftButton" @click="$emit('click')">
    <i v-if="right" class="el-icon-arrow-right"></i>
    <i v-else class="el-icon-arrow-left"></i>
  </div>
</template>

<script>
export default {
  name: 'ShiftButton',
  props: {
    right: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.ShiftButton {
  width: 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.1);
  cursor: pointer;
  font-size: 28px;
  color: #fff;
  z-index: 101;
}
</style>