<template>
  <HeaderItem class="navDropdown" title="下拉导航">
    <el-popover
      ref="navPopover"
      placement="bottom-start"
      trigger="hover"
      :width="540"
      :offset="-80"
    >
      <template slot="reference">
        <el-image class="navIcon" :src="navUrl"></el-image>
      </template>
      <div class="navOuter">
        <NavCenter
          title="监控中心"
          v-if="MonitoringCenter.length"
          :navData="MonitoringCenter"
          @click="goRoute"
        ></NavCenter>
        <NavCenter
          title="管理中心"
          v-if="ManagementCenter.length"
          :navData="ManagementCenter"
          @click="goRoute"
        ></NavCenter>
        <NavCenter
          title="查询中心"
          v-if="QueryCenter.length"
          :navData="QueryCenter"
          @click="goRoute"
        ></NavCenter>
        <NavCenter
          title="系统中心"
          v-if="SystemCenter.length"
          :navData="SystemCenter"
          @click="goRoute"
        ></NavCenter>
        <NavCenter
          title="大数据中心"
          v-if="BigDataCenter.length"
          :navData="BigDataCenter"
          @click="goRoute"
        ></NavCenter>
      </div>
    </el-popover>
  </HeaderItem>
</template>

<script>

import { mapState, mapMutations } from 'vuex';

import * as MonitoringCenter from '@/router/modules/MonitoringCenter';
import * as ManagementCenter from '@/router/modules/ManagementCenter';
import * as QueryCenter from '@/router/modules/QueryCenter';
import * as SystemCenter from '@/router/modules/SystemCenter';
import * as BigDataCenter from '@/router/modules/BigDataCenter';

import HeaderItem from '@/layout/HeaderItem';
import NavCenter from './NavCenter.vue';
import { hasPerms } from '@/utils/auth';

export default {
  name: 'NavDropdown',
  components: {
    HeaderItem,
    NavCenter,
  },
  data() {
    return {
      navUrl: require('@/assets/images/header/下拉导航.png'),
    };
  },
  computed: {
    ...mapState(['headerMenus']),
    // 监控中心导航菜单
    MonitoringCenter() {
      return Object.values(MonitoringCenter).filter(item => hasPerms(item.meta.permId) && item.meta.hideMenu !== true );
    },
    // 管理中心导航菜单
    ManagementCenter() {
      return Object.values(ManagementCenter).filter(item => hasPerms(item.meta.permId));
    },
    // 查询中心导航菜单
    QueryCenter() {
      return Object.values(QueryCenter).filter(item => hasPerms(item.meta.permId));
    },
    // 系统中心导航菜单
    SystemCenter() {
      return Object.values(SystemCenter).filter(item => hasPerms(item.meta.permId));
    },
    // 大数据中心菜单
    BigDataCenter() {
      return Object.values(BigDataCenter).filter(item => hasPerms(item.meta.permId) && item.meta.isShow !== false);
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setHeaderMenus']),
    goRoute(nav) {
      this.$router.push(nav.path);
      // 关闭 Popper
      this.$refs.navPopover?.doClose();

      const { headerMenus } = this.$store.state;
      if (!headerMenus.some(menu => menu.name === nav.name)) {
        headerMenus.push(nav);
        this.setHeaderMenus(headerMenus);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navDropdown {
  cursor: pointer;
  margin-right: 0px;
}
.navIcon {
  height: 100%;
}

.navPopper {
  height: 400px;
}
.navOuter {
  // 覆盖父节点设置了padding: 12px;
  // 与父节点完成全重合
  margin: -13px -13px;
  height: 400px;
  display: flex;
}
// 重置箭头颜色
::v-deep.navOuter + div.popper__arrow {
  border-bottom-color: #475568 !important;
  &::after {
    border-bottom-color: #475568 !important;
  }
}
</style>