<template>
  <div class="navCenter">
    <div class="navTitle">{{title}}</div>
    <div class="navLinkOuter">
      <div
        class="navLink"
        v-for="nav in navList"
        :key="nav.name"
        :to="nav.path"
        @click="onGo(nav)"
      >
        <Iconfont :name="getIconName(nav)" :mRight="6"></Iconfont>
        {{nav.meta.label}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavCenter',
  props: {
    // 导航中心title
    title: {
      type: String,
      default: '',
    },
    // 导航数据 Array<RouteMenu>
    navData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    navList() {
      return this.navData.filter(val => val.meta.show !== false);
    }
  },
  methods: {
    /**
     * nav: RouteMenu
     */
    onGo(nav) {
      this.$emit('click', nav);
    },
    getIconName(menu) {
      const { meta } = menu;
      if (Array.isArray(meta.icon)) return meta.icon[0];
      return meta.icon;
    }
  }
}
</script>

<style lang="scss" scoped>
.navCenter {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.navTitle {
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #475568;
  color: #efefef;
  font-size: 14px;
  font-weight: 300;
}
.navLinkOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;
  border-right: 1px dashed #707070;
  .navCenter:last-child > & {
    border-right: 1px solid #fff;
  }
}
.navLink {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #343434;
  height: 36px;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #e8e8e8;
  }
}
.navIcon {
  margin-right: 6px;
}
</style>