<template>
  <Iconfont :size="18" name="icon-chezu" :mRight="7"></Iconfont>
  <!-- <img class="avatorImg" :src="avatorImg" /> -->
</template>

<script>
export default {
  name: 'UserAvator',
  computed: {
    avatorImg() {
      return require('@/assets/images/组织树头像.png');
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
