<template>
  <div class="ExpiredReminder reportDialog">
    <el-dialog title="平台到期提醒"
      width="1200px"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="dialogClose">
      <el-container style="height:400px">
        <el-header style="height:40px;padding:0;">
          <el-row>
            <el-col :span="7">
              <label style="margin-right:5px">过期时间</label>
              <el-date-picker v-model="reminderDate"
                type="date"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-col>
            <el-col :span="11">
              <el-radio-group v-model="dateTypeDate"
                @change="radioChange">
                <el-radio-button v-for="item in dateType"
                  :key="item.value"
                  :label="item.value">{{item.label}}</el-radio-button>
              </el-radio-group>
            </el-col>
            <el-col :span="2">
              <el-button type="primary"
                @click="onSearch(dateTypeDate,1)">搜索</el-button>
            </el-col>
            <el-col :span="2">
              <el-button @click="openAuthCoin">批量授权</el-button>
            </el-col>
            <el-col :span="2">
              <BtnExport style="width:90px;height:32px"
                :disabled="!data.length>0"
                title="到期提醒"
                exportUrl="remindExpire/exportVehicleRemindExcel.do"
                exportDataType="1"
                :params="{
                  remindType:3,
                  endTime:this.reminderDate,
                  state:0,
                  userId:1,
                  pageSize:5000,
                }" />
            </el-col>
          </el-row>
        </el-header>
        <el-main style="height:calc(100% - 40px);padding:0 0px 5px 0px;">
          <el-table :data="data"
            size="mini"
            height="calc(100% - 32px)"
            @selection-change="changeTableData">
            <el-table-column type="selection"
              width="50px"></el-table-column>
            <el-table-column label="序号"
              type="index"
              width="50px"></el-table-column>
            <el-table-column label="操作"
              width="50px">
              <template slot-scope="scope">
                <el-button type="text"
                  @click="rechargeIng(scope.row)">授权</el-button>
              </template>
            </el-table-column>
            <el-table-column label="设备名称"
              prop="plate"
              width="100px"></el-table-column>
            <el-table-column label="颜色"
              prop="plateColor"
              width="80px"></el-table-column>
            <el-table-column label="车组"
              prop="groupName"></el-table-column>
            <el-table-column label="终端号"
              prop="terminalNo"
              width="120px"></el-table-column>
            <el-table-column label="终端类型"
              prop="terminalType"
              width="120px"></el-table-column>
            <el-table-column label="SIM"
              prop="sim"></el-table-column>
            <el-table-column label="到期时间"
              prop="expireTime"></el-table-column>
            <el-table-column label="安装时间"
              prop="installDate"></el-table-column>
          </el-table>

          <el-pagination background
            layout="total, prev, pager, next, jumper"
            style="text-align:center"
            :current-page="tablePage.pageNumber"
            :page-size="tablePage.pageSize"
            :total="tablePage.total"
            @current-change="changePage"></el-pagination>
        </el-main>
      </el-container>
    </el-dialog>
    <!-- 授权 -->
    <AuthCoin :dialogVisible="authCoinDialog"
      :vehicleList="vehicleList"
      @input="closeDialog"></AuthCoin>

    <!-- 外层图标 -->
    <div class="icon-pane"
      @click="dialogVisibleClick">
      <el-badge is-dot
        v-show="badgeRad"
        class="item badgeRad"></el-badge>
      <Iconfont name="icon-xiaoxitongzhi"
        :size="21"></Iconfont>
    </div>
  </div>
</template>

<script>

/**
 * 到期提前组件
 */

import AuthCoin from '@/components/AuthCoin';
// 导出组件
import BtnExport from "@/components/Export";
// 时间格式转换
import moment from 'dayjs';
//搜索接口
import { getVehicleRemind } from '@/api/All-Interface.js';
import { mapState } from 'vuex';
import { hasPerms, dataPermissions } from "@/utils/auth";

export default {
  name: 'ExpiredReminder',
  data() {
    return {
      data: [],
      reminderDate: 0, // 提醒时间
      dateTypeDate: -1, // 快捷选择时间
      dateType: [
        { label: '已过期', value: -1 },
        { label: '今天', value: 0 },
        { label: '近三天', value: 2 },
        { label: '近七天', value: 6 },
        { label: '近三十天', value: 29 },
      ],

      authCoinDialog: false,
      tablePage: {
        //分页
        pageNumber: 1,
        pageSize: 50,
        total: 0,
      },
      vehicleList: [],// 传入的授权所需数据

      dialogVisible: false,// 弹窗开启关闭
      badgeRad: false, //小红点效果
    };
  },
  components: {
    AuthCoin,
    BtnExport,

  },
  computed: {
    ...mapState(['userConfig']),
    autoNoticeExpired() {
      return this.userConfig.realTimeConfig.autoNoticeExpired;
    }
  },
  methods: {
    dialogVisibleClick() {
      this.dialogVisible = !this.dialogVisible;
    },
    // 关闭弹窗
    dialogClose() {
      this.dialogVisible = false;
    },
    radioChange(val) {
      //到期时间获取
      const start = new Date();
      if (val === -1) {
        start.setTime(start.getTime());
        this.reminderDate = moment(new Date(start)).format("YYYY-MM-DD HH:mm:ss");
      } else {
        start.setTime(start.getTime() + 3600 * 24 * 1000 * val);
        this.reminderDate = moment(new Date(start)).format("YYYY-MM-DD 23:59:59");
      }
    },

    async onSearch(val, current) {
      try {
        if (val === -1) {
          const res = await getVehicleRemind({
            remindType: 3,
            pageNumber: current,
            pageSize: 100
          });
          this.data = res.rows;
          if (res.rows.length) {
            this.dialogVisible = this.autoNoticeExpired;
          }
          if (this.data.length === 0) { // 如果有过期数据，则显示小红点
            this.badgeRad = false;
          } else {
            this.badgeRad = true;
          }
          this.tablePage.total = res.total;
        } else {
          const res = await getVehicleRemind({
            remindType: 3,
            endTime: this.reminderDate,
            pageNumber: current,
            pageSize: 100
          });
          this.data = res.rows;
          this.tablePage.total = res.total;
        }
      } catch (error) {
        console.log(error);
        this.$message.warning("查询异常");
      }
    },
    //分页改变每页数量
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.onSearch(this.dateTypeDate, current);
    },
    // 打开批量授权弹窗
    openAuthCoin() {
      if (this.vehicleList.length == 0) {
        this.$message.error("请先选择车辆！");
        return;
      }
      this.authCoinDialog = true;
    },
    // 批量授权
    changeTableData(selection) {
      this.vehicleList = selection.map(p => {
        return {
          "vehicleId": p.vehicleId,
          "plate": p.plate,
          "terminalNo": p.terminalNo,
          "terminalType": p.terminalType,
          "groupId": p.groupId,
        };
      });
    },
    // 授权组件关闭
    closeDialog(model) {
      this.authCoinDialog = model;
    },

    // 单个授权
    rechargeIng(val) {
      this.vehicleList = [{
        "vehicleId": val.vehicleId,
        "plate": val.plate,
        "terminalNo": val.terminalNo,
        "terminalType": val.terminalType,
        "groupId": val.groupId,
      }];
      this.authCoinDialog = true;
    }
  },
  mounted() {
   if (hasPerms('M01')) {
    this.radioChange(this.dateTypeDate);
    this.onSearch(-1, 1);
   }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/style/reportTreeAndData.scss';
.ExpiredReminder {
  ::v-deep .el-dialog__body {
    padding: 20px 20px 0 20px !important;
  }
  .icon-pane {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #fff;
    position: relative;
    top: 20px;
    left: 5px;
    > span {
      font-size: 14px;
    }

    .badgeRad {
      position: absolute;
      top: -3px;
      right: 3px;
      z-index: 1;

      ::v-deep .is-dot {
        width: 6px;
        height: 6px;
      }
    }
  }
}
</style>