import { render, staticRenderFns } from "./AvatarWithText.vue?vue&type=template&id=9ff9a050&scoped=true"
import script from "./AvatarWithText.vue?vue&type=script&lang=js"
export * from "./AvatarWithText.vue?vue&type=script&lang=js"
import style0 from "./AvatarWithText.vue?vue&type=style&index=0&id=9ff9a050&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ff9a050",
  null
  
)

export default component.exports