<template>
  <div
    class="select-tree"
    :style="{height: height+ 'px', lineHeight: height+ 'px'}"
  >
    <el-autocomplete
      class="inline-input"
      popper-class="select-option"
      :size="size"
      :disabled="disabled"
      :debounce="500"
      :maxlength="30"
      ref="inlineInput"
      v-model="searchStr"
      :fetch-suggestions="querySearchAsync"
      :placeholder="placeholder"
      :trigger-on-focus="false"
      :clearable="clearable"
      @select="onSelect"
      @clear="onClear"
    >
      <template slot-scope="{item}">
        <div
          class="name"
          style="width:300px"
          @click="itemClick(item)"
        >[{{{1:"组织",2:"车辆",3:"驾驶员"}[item.type]}}]{{ item.plate }} {{item.groupName}}{{item.driverName}}</div>
      </template>
      <template slot="append">
        <el-popover placement="top" v-model="visible">
          <div class="tree-item" v-loading="isLoading">
            <div class="group-item">
              <el-divider content-position="left">组织</el-divider>
              <GroupTree
                class="group-item_tree"
                :showCheckbox="multipleProp.groupCheckBox"
                ref="groupTree"
                @checkChange="groupCheckClick"
                @checkChangeGroup="checkChangeGroup"
                @nodeClick="groupNodeClick"
                @dbClick="dbGroupClick"
                :selecteds="selecteds"
                :useDefault="true"
              />
            </div>
            <div class="vehicle-item" v-if="!singleGroup">
              <el-divider content-position="left">车辆</el-divider>
              <VirtualTree
                ref="virtualTreeRef"
                style="height: 94%;"
                :emptyText="emptyText"
                :data="vehicleData"
                :defaultExpandAll="false"
                :showCheckbox="multipleProp.vehicleCheckBox"
                :fieldNames="{
                    key:'vehicleId',
                    label:'plate'
                  }"
                :isEffluence="limit == selectVehicle.length"
                @node-click="onNodeClick"
                @check-change="onCheckChange"
              >
                <template v-slot="{node}">
                  <div class="vehicle-node">
                    <VehicleAvator :data="node"></VehicleAvator>
                    <span :title="node.plate">
                      {{node.plate}}
                      <el-tag
                        size="mini"
                        :type="colorData[node.vehicleStatus]"
                      >{{['怠速', '行驶', '作业', '通电', '熄火', '离线', '从未上线'][node.vehicleStatus] || '从未上线' }}</el-tag>
                    </span>
                    <label v-if="$scopedSlots.action">
                      <slot name="action" :node="node"></slot>
                    </label>
                  </div>
                </template>
              </VirtualTree>
            </div>
          </div>

          <div
            style="text-align: right; margin: 0"
            v-if="multipleProp.vehicleCheckBox ||multipleProp.groupCheckBox"
          >
            <el-button size="mini" type="text" @click="channelClick">取消</el-button>
            <el-button type="primary" size="mini" @click="submit">确定</el-button>
          </div>

          <el-button
            slot="reference"
            :style="{height: height - 1 + 'px', lineHeight:  height -1 + 'px', padding: 0}"
            :disabled="disabled"
            @click="open"
          >
            <label
              class="tip-pane"
              v-if="showTip()"
            >+{{`${ (alone == 'vehicle' ? this.selectVehicle.length : this.selectVehicle.length) || this.selecteds.length} `}}</label>
            <!-- + this.selectGroups.length  -->
            <i class="el-icon-search el-icon--right" style="margin-right: 5px"></i>
          </el-button>
        </el-popover>
      </template>
    </el-autocomplete>

    <!-- <div class="multiple-str" v-if="showTip()">
      <label
        class="tip-pane"
      >+{{`${this.selectVehicle.length + this.selectGroups.length || this.selecteds.length} `}}</label>
    </div>-->
  </div>
</template>

<script>
import GroupTree from '@/components/GroupTree';
import VirtualTree from '@/components/VirtualTree';
import VehicleAvator from './VehicleAvator';
import { getVehiclesByGroupId, searchMsg } from '@/api/getManagementData';
import { arrayToTree } from '@/utils/treeHelper.js';
import { stringify } from 'qs';

export default {
  name: 'SelectGroupTree',
  components: {
    GroupTree,
    VirtualTree,
    VehicleAvator,
  },
  props: {
    limit: { // 限制选择车辆数
      type: Number,
      default: -1
    },
    singleGroup: {//是否只展示组织
      type: Boolean,
      default: false
    },
    multipleProp: { //多选模式参数
      type: Object,
      default() {
        return { // 车组多选和车辆多选不能同时为true 同时设置了优先显示车组
          noGroup: false, // 是否可选车组
          groupCheckBox: false, //车组多选
          vehicleCheckBox: false, //车辆多选
        };
      },
    },
    disabled: { // 禁用状态
      type: Boolean,
      default: false
    },
    normal: { // 0 2 3 4 5 状态可选
      type: Boolean,
      default: false
    },
    alone: {
      type: String,
      default: ''
    },
    /**
     * 输入框是否空清空
     */
    clearable: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否默认勾选车辆
     */
    defaultCheck: {
      type: Boolean,
      default: true,
    },
    size: { // input框size
      type: String,
      default: 'small'
    },

    selecteds: {//已选择的数据 用于修改功能 仅多选可用
      type: Array,
      default() {
        return []; // { label:车牌/组织, id:车辆ID/车组ID }
      }
    },
    inputVehicle: { // 输入查询车辆
      type: Boolean,
      default: true,
    },
    inputGroup: { // 输入查询车组
      type: Boolean,
      default: true,
    },
    inputDriver: { // 输入查询驾驶员
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '车组/设备名称'//终端号
    },
    height: {
      type: Number,
      default: 34
    }
  },
  data() {
    return {
      vehicleList: [], // 车辆数据
      selectGroups: [], //已选择的车组
      selectVehicle: [], //已选的车辆
      emptyText: '--',
      input: '',
      visible: false,
      selectCount: 0,
      disabledCheck: true,
      colorData: {
        0: 'warning',
        1: 'success',
        2: 'success',
        3: '',
        4: 'danger',
        5: 'info',
        6: 'info',
        7: 'info',
      },
      isLoading: false
    };
  },
  mounted() {
    this.setSelecteds(this.selecteds);
  },

  watch: {
    selecteds: {
      handler(val) {
        if (val.length) {
          this.setSelecteds(this.selecteds);
        }
      }
    },

    selectGroups: {
      handler(val, oldVal) {
        if (!val.length) {
          if (!this.selectVehicle.length) {
            return this.alone != 'vehicle' && this.setInputStr('');
          }
          // else {
          //   if (oldVal.length) {
          //     const oldValIds = oldVal.map(item => item.groupId);
          //     this.selectVehicle = this.selectVehicle.filter(item => !oldValIds.includes(item.groupId));
          //     const params = this.selectVehicle.map(item=> item.plate || item.P).toString();
          //     return this.alone != 'vehicle' && this.setInputStr(params);
          //   }
          //   const params = this.selectVehicle.map(item=> item.plate || item.P).toString();
          //   return this.alone != 'vehicle' && this.setInputStr(params);
          // }
        } else {
          const valIds = val.map(item => item.groupId);
          const oldValIds = oldVal.filter(item => !valIds.includes(item.groupId)).map(item2 => item2.groupId);
          this.selectVehicle = this.selectVehicle.filter(item => !oldValIds.includes(item.groupId));
          //   const params = this.selectVehicle.map(item=> item.plate || item.P).toString();
          //   return this.setInputStr(params);
        }
        // const data = val.map(item=> item.groupName).toString();
        // if(this.alone != 'vehicle') this.setInputStr(data);
      }
    },

    selectVehicle: {
      handler(val) {
        // if (!val.length && !this.selectGroups.length) return this.setInputStr('');
        if (val.length && this.alone != 'vehicle') {
          const params = val.map(item => item.plate || item.P).toString();
          this.setInputStr(params);
        } else {
          this.setInputStr('');
        }
      },
      deep: true
    },
  },
  computed: {
    // 车辆树
    vehicleData() {
      return arrayToTree(this.vehicleList, { id: 'vehicleId' });
    },
    searchStr: {
      get: function () {
        return this.input || '';
      },
      set: function (value) {
        this.setInputStr(value);
      }
    },

  },
  methods: {

    itemClick(item) {
    },
    // 设置已选数据
    setSelecteds(list) {
      const labels = list.map(p => p.label);
      this.setInputStr(labels[0]);
    },
    showTip() {
      const { groupCheckBox, vehicleCheckBox } = this.multipleProp;
      return groupCheckBox || vehicleCheckBox;
    },

    // 点击取消
    channelClick() {
      this.visible = false;
      this.$emit('on-channel');
      this.reset();
    },
    //组织树节点点击事件
    groupNodeClick(node, checked) {
      this.currentGroup = node;
      if (this.singleGroup) {
        this.visible = false;
        this.setInputStr(node.groupName);
      }
      if (!this.multipleProp.groupCheckBox && this.singleGroup) return this.$emit('select-group', node);

      this.getVehicleListById(node.groupId, this.multipleProp.groupCheckBox ? checked : this.multipleProp.vehicleCheckBox);
    },

    //组织树双击事件
    dbGroupClick(node) {
      if (!this.multipleProp.noGroup) return;
      this.visible = false;
      const text = node.groupName;
      this.$emit('dbClick', node);

      this.setInputStr(text);
    },

    //加载车辆数据
    async getVehicleListById(groupId, checked, crew) {
      const oldVehicleList = JSON.parse(JSON.stringify(this.$refs.virtualTreeRef.visibleData));
      this.isLoading = true;
      const result = await getVehiclesByGroupId({ groupIds: groupId });
      this.isLoading = false;
      if (result.flag !== 1) return;
      if (this.selecteds && this.selecteds.length) { // 处理已选择数据(修改)
        const list = this.selecteds.map(p => p.id);
        this.vehicleList = result.obj.map(item => {
          return {
            ...item,
            checked: checked ? list.includes(item.vehicleId) : false,
            disabled: this.handleFilterCar(item)
          };
        });
        return;
      }

      const { selectVehicle } = this; //已选择数据设置勾选状态
      this.vehicleList = result.obj.map(item => {
        // && !oldVehicleList.some(p => p.vehicleId == item.vehicleId && !p.checked)
        if (!(this.normal && (item.vehicleStatus > 5 || item.vehicleStatus == 1)) && crew && this.defaultCheck && this.multipleProp.vehicleCheckBox && !selectVehicle.some(p => p.vehicleId == item.vehicleId)) {
          selectVehicle.push(item);
        }
        return {
          ...item,
          checked: oldVehicleList.some(p => p.vehicleId == item.vehicleId && !p.checked) ? false : selectVehicle.some(p => p.vehicleId == item.vehicleId),
          disabled: this.handleFilterCar(item)
        };
      });


      // if (selectVehicle.length === this.limit) { // 限制勾选状态
      //   this.vehicleList = this.vehicleList.map(item => ({
      //     ...item,
      //     disabled: !item.checked
      //   }));
      // }
    },

    //重置
    reset() {
      this.selectVehicle = [];
      this.selectGroups = [];
      this.$refs.groupTree.cancelAll();
      // 解决搜索后清空再次搜索无效问题
      this.$refs.inlineInput.handleFocus();
      // document.activeElement.blur()

      this.checkedFilter(false);
    },

    // 判断是否可选
    handleFilterCar(item) {
      let flag = false;
      //  || ((this.limit !== -1 && this.selectVehicle.length >= this.limit) && !this.selectVehicle.filter(val => val.vehicleId == item.vehicleId).length)

      if ((this.normal && (item.vehicleStatus > 5 || item.vehicleStatus == 1))) {
        flag = true;
      }
      return flag;
      // (this.normal && (item.vehicleStatus > 5 || item.vehicleStatus == 1)) && (this.limit !== -1 && this.selectVehicle.length >= this.limit)
    },

    // 组织复选框勾选事件
    async groupCheckClick(node, checked) {
      // console.log(this.multipleProp.groupCheckBox);
      if (!this.multipleProp.groupCheckBox) {
        checked = true;
      }
      if (!node) return;
      this.isIndeterminate = true;
      // if (this.vehicleList && this.vehicleList.length > 0 && this.vehicleList[0].groupId == node.groupId && this.defaultCheck) {
      //   this.checkedFilter(checked);
      //   this.$forceUpdate();
      // }
      // else if (!this.vehicleList || this.vehicleList.length == 0 || this.vehicleList[0].groupId !== node.groupId) {
      //   await this.getVehicleListById(node.groupId, this.multipleProp.groupCheckBox ? checked : this.multipleProp.vehicleCheckBox, true);
      // }
      if (checked) {
        if (!this.multipleProp.noGroup) {
          this.checkChangeGroup([node]);
        }
        this.$emit('select-group', node);
        // (( ? '' : (this.multipleProp.groupCheckBox ? this.selectGroups.push(node) : this.selectGroups = [node])),this.$emit('select-group', node));
        return false;
      }
      if (!checked) {
        if (this.multipleProp.vehicleCheckBox) {
          this.selectVehicle = this.selectVehicle.filter(item => item.groupId !== node.groupId && item.groupId !== node.parentId);
        }
        this.selectGroups = this.selectGroups.filter(item => {
          if (item.groupId !== node.groupId && item.groupId !== node.parentId) {
            return true;
          } else if (item.groupId == node.parentId) {
            // console.log('半选');
            return false;
          }
          return false;
        });
      };
    },
    // 选中车组数组
    async checkChangeGroup(data, checked) {
      // if (this.multipleProp.groupCheckBox) {
      if (data && data.length) {
        this.selectGroups = data;
        await this.getVehicleListById((data && data.length ? data.map(val => val.groupId).toString() : ''), this.multipleProp.groupCheckBox ? checked : this.multipleProp.vehicleCheckBox, true);
      } else {
        this.vehicleList = [];
        this.selectVehicle.length ? this.selectVehicle = [] : '';
      }
      // if (checked) {
      //   if (!this.multipleProp.noGroup) {
      //     this.checkChangeGroup([node])
      //   }
      //   this.$emit('select-group', node)
      //   // (( ? '' : (this.multipleProp.groupCheckBox ? this.selectGroups.push(node) : this.selectGroups = [node])),this.$emit('select-group', node));
      //   return false;
      // }
      // if (!checked) {
      //   this.selectGroups = this.selectGroups.filter(item => {
      //     if (item.groupId !== node.groupId && item.groupId !== node.parentId) {
      //       return true;
      //     }else if (item.groupId == node.parentId) {
      //       // console.log('半选');
      //       return false;
      //     }
      //     return false;
      //   });
      //   if (this.multipleProp.vehicleCheckBox) {
      //     this.selectVehicle = this.selectVehicle.filter(item => item.groupId !== node.groupId && item.groupId !== node.parentId);
      //   }
      // };
      // }
    },

    //  多选确定点击事件
    submit() {
      this.visible = false;
      const groupList = this.selectGroups;
      const vehicleList = this.selectVehicle;
      this.$emit('on-confirm', { groupList, vehicleList });
    },

    // 车辆树节点点击事件
    onNodeClick(node) {
      if (!this.multipleProp.vehicleCheckBox) {
        this.visible = false;
        if (this.normal && (node.vehicleStatus > 5 || node.vehicleStatus == 1)) {
          this.$message.error('该车辆不能查询');
          return false;
        }
        this.$emit('on-vehicle', node);
        this.setInputStr(node.plate);
      }
    },
    // 车辆树节点勾选事件
    onCheckChange(node, checked) {
      // this.setTreeNodeDisable(this.selectVehicle, false);
      if (!checked) {
        this.selectVehicle = this.selectVehicle.filter(item => item.vehicleId !== node.vehicleId);
      } else if (!this.multipleProp.vehicleCheckBox) {
        this.selectVehicle = [node];
      } else {
        //判断最大可选车辆
        if (this.limit !== -1 && this.selectVehicle.length >= this.limit) {
          this.$refs.virtualTreeRef.setChecked(node, false);

          return this.$message.warning(`最多只可选择${ this.limit }台设备！`);
        }

        this.selectVehicle.push(node);
      }

      this.$emit('on-check', this.selectVehicle);

      // if (this.selectVehicle.length === this.limit) return this.setTreeNodeDisable(this.selectVehicle, true); // 勾选框可选状态
    },
    checkedFilter(checked) {
      if (checked) {
        this.vehicleList = this.vehicleList.map(val => {
          if (!(this.normal && (val.vehicleStatus > 5 || val.vehicleStatus == 1)) && this.multipleProp.vehicleCheckBox && (this.limit !== -1 ? this.selectVehicle.length >= this.limit : true)) {
            this.selectVehicle.push(val);
            val.checked = true;
          } else {
            val.checked = false;
          }
          return { ...val };
        });
      } else {
        this.vehicleList = this.vehicleList.map(val => ({ ...val, checked: false }));
      }
    },

    // 设置可选框状态
    setTreeNodeDisable(selects = [], disabled = false) {
      this.$refs.virtualTreeRef.getAllNodes().forEach(node => {
        if (!selects.map(p => p.vehicleId).includes(node.vehicleId)) {
          this.$refs.virtualTreeRef.setDisabledNode(node.vehicleId, disabled);
        }
      });
    },

    //清除选择
    onClear() {
      this.reset();
      this.$emit('clear');
    },

    open() {
      // this.vehicleList = [];
    },

    async querySearchAsync(queryString, cb) {
      const result = await searchMsg({ param: queryString });
      if (result.flag !== 1) return cb([]);
      const { groupNameList, plateList, driverNameList } = result.obj;
      let groupList = groupNameList ? groupNameList.map(item => ({ ...item, type: 1 })) : []; //车组类型数据
      let vehicleList = plateList ? plateList.map(item => ({ ...item, type: 2 })) : [];//车辆类型数据
      let driverList = driverNameList ? driverNameList.map(item => ({ ...item, type: 3 })) : []; // 驾驶员
      if (!this.inputVehicle) vehicleList = [];
      if (!this.inputGroup) groupList = [];
      if (!this.inputDriver) driverList = [];

      const params = await [...groupList, ...vehicleList, ...driverList];
      cb(params);
      this.$forceUpdate();
    },

    //输入建议选择事件
    onSelect(item) {
      let text = !item.plate ? item.groupName : item.plate;
      if (item.driverName) text = item.driverName;
      this.setInputStr(text);
      this.selectVehicle.length ? this.selectVehicle = [] : '';
      // if (!this.multipleProp.vehicleCheckBox) { // 多选的情况已经触发了勾选事件不需要重复push
      //   this.selectVehicle.push(item);
      // }
      if (item.type == 1) {
        // this.selectGroups.push(item);
        this.$refs.groupTree.handleSelect(item);
      } else {
        //  if (!this.selectVehicle.some(val=> val.vehicleId == item.vehicleId)) 
        // this.selectVehicle.push(item);
        this.selectVehicle = [item];
      }

      this.$emit('on-select', item);
    },

    setInputStr(item) {
      this.input = item;
    }
  }
}
</script>

<style lang="scss" scoped>
.select-tree {
  position: relative;
  display: flex;
  align-items: center;
}
.tree-item {
  // width: auto;
  width: 400px;
  height: 350px;
  display: flex;
}
.group-item {
  position: relative;
  // width: 180px;
  width: 400px;
  padding-right: 10px;
}
.group-item_tree {
  height: calc(100% - 21px);
}
.vehicle-item {
  width: 280px;
  border-left: 1px #ddd solid;
  padding-left: 15px;
}

.group-node {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 8px;
  & > span {
    width: 100px;
    height: auto;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.el-divider--horizontal {
  margin: 10px 0;
}
// .multiple-str {
//   position: absolute;
//   right: 40px;
//   top: 0px;
//   bottom: 1px;
//   border-bottom: 1px solid #DCDFE6;
//   border-top: 1px solid #DCDFE6;
.tip-pane {
  display: inline-block;
  // width: 20px;
  // height: 20px;
  // line-height: 34px;
  // border-radius: 50%;
  color: #66b1ff;
  // background: rgba(102, 177, 255, 0.2);
  padding: 0px 6px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
}
// }
.vehicle-node {
  & > span {
    width: 100%;
    height: auto;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.inline-input {
  width: 90%;
}

::v-deep .el-input__inner {
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
  //   border-right: none;
  // height: 32px;
}

::v-deep .el-button--small {
  border-radius: 0 4px 4px 0;
  border-left: none;
}
</style>

<style lang="scss">
.select-option {
  width: auto !important;
}
</style>
