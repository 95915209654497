<template>
  <div class="adas-modal-wrap">
    <div
      :style="{ height: 'calc(100% - ' + pageHeight + 'px)' }"
      class="media-content"
      @dblclick="onDblClick"
    >
      <img
        style="height:100%;width:100%;"
        v-if="mediaObj.isPicture"
        :src="mediaObj.src"
      />
      <AdasVideo v-if="mediaObj.isAV" :videoSrc="mediaObj.src"></AdasVideo>
    </div>
    <div
      v-show="pageHeight > 0"
      :style="{ height: pageHeight + 'px' }"
      class="page-bottom"
    >
      <el-pagination
        style="width: 100px;"
        class-name="adas-notice-page"
        size="small"
        :current-page="pageCurrent"
        :page-size="1"
        :total="pageTotal"
        @current-change="onPageChange"
      ></el-pagination>

      <el-button
        style="font-size: 14px;display:inline;float: right; margin-top: -33px;"
        @click="downloadImg"
      >下载</el-button>
    </div>
  </div>
</template>
<script>

import AdasVideo from "@/components/AdasVideo";
import AdasPicture from "@/components/AdasPicture";
import { pictureShow, pictureDownload } from '@/api/getReport.js';
import { downloadFile } from "@/utils/downloadFile";

export default {
  name: "DrivingMediaPage",
  props: {
    adasData: null
  },
  data() {
    return {
      mediaId: Math.random()
        .toString(36)
        .substr(2),
      adasFiles: [],
      mediaObj: {
        isPicture: false,
        isAV: false,
        src: null
      },
      pageCurrent: 0,
      wsRequest: null
    };
  },
  methods: {
    onDblClick() {
      let file = this.adasFiles[this.pageCurrent - 1];
      this.$emit("dblclick", file);
    },
    resolveMedia(value) {
      let descMedias = [
        ["isAudio", "0x01", 1, "音频"],
        ["isVideo", "0x02", 2, "视频"],
        ["isAV", "0x03", 0, "音视频"],
        ["isPicture", "0x04", 5, "图片"],
        ["isLocate", "0x08", -1, "定位"]
      ];
      let media = {};
      descMedias.forEach(p => {
        let flag = Number(value) & Number(p[1]) ? true : false;
        if (flag) {
          media[p[0]] = flag;
          media.value = p[2];
          media.desc = p[3];
        }
      });
      return media;
    },
    onPageChange(current) {
      this.pageCurrent = current;
      let file = this.adasFiles[current - 1];
      file.src = file.ftpServerPath + file.filefullname;

      let adas = { ...file };

      if (adas.media == 4) {
        this.adasOpenImg(adas);
      } else {
        this.adasOpen(adas);
      }
    },
    adasOpen(file) {
      let media = this.resolveMedia(file.media);
      this.mediaObj.src = file.src;
      this.mediaObj.isPicture = media.isPicture;
      this.mediaObj.isAV = media.isAV || media.isVideo || media.isAudio || media.isSupportMedia;
    },
    adasOpenImg(file) {
      this.mediaObj.src = file.src;
      this.mediaObj.isPicture = true;
      this.mediaObj.isAV = false;
      return;

      pictureShow({
        path: file.rootPath + file.filefullname
      }).then(res => {
        if (res.size > 0) {
          this.mediaObj.src = URL.createObjectURL(res);
          this.mediaObj.isPicture = true;
          this.mediaObj.isAV = false;
        } else {
          this.mediaObj.src = "";
          this.mediaObj.isPicture = true;
          this.mediaObj.isAV = false;
        }
      });
    },
    downloadImg() {
      let current = this.pageCurrent;
      let item = this.adasFiles[current - 1];
      let download_imgname = item.plate + "-" + item.alarm + "-" + item.time;
      let filename = download_imgname + ".jpg";
      if (item.media == 3) {
        let lowercase = item.filefullname.toLowerCase();
        if (lowercase.endsWith(".mp4")) {
          filename = download_imgname + ".mp4";
        } else if (lowercase.endsWith(".avi")) {
          filename = download_imgname + ".avi";
        } else {
          filename = item.filefullname;
        }
      }

      //NOTE: 统一使用路径下载方式,后面便需求可能变动
      const flag = true;

      const imgDate = new Date(item.time);
      if (flag || imgDate.format("yyyy-MM-dd") != new Date().format("yyyy-MM-dd")) {
        downloadFile(item.src, filename);
      } else {
        const download_imgurl = item.rootPath + item.filefullname;
        pictureDownload({
          path: download_imgurl
        }).then(res => {
          if (res.size > 0) {
            let href = URL.createObjectURL(res);
            downloadFile(href, filename);
          }
        });
      }
    },

    beforeDestroy() {
      this.adasFiles = [];
    },
  },
  computed: {
    pageHeight() {
      return this.adasFiles.length > 1 ? 46 : 0;
    },
    pageTotal() {
      return this.adasFiles.length;
    }
  },
  watch: {
    adasData: {
      deep: true,
      immediate: true,
      handler: function (val, oldval) {
        this.adasFiles = [];
        let tth = setTimeout(() => {
          clearTimeout(tth);
          if (this.adasData && this.adasData.length > 0) {
            this.adasFiles = this.adasData.filter(p => p.filefullname
              && !p.filefullname.toLowerCase().endsWith(".bin"));
            this.onPageChange(1);
          }else {
            this.mediaObj = {
              isPicture: false,
              isAV: false,
              src: null
            }
          }
        }, 400);
      }
    }
  },
  mounted() {
  },
  destroyed() {
    this.adasFiles = [];
  },
  components: {
    AdasVideo,
    AdasPicture
  }
};
</script>
<style lang="scss">
.adas-modal-wrap {
  width: 100%;
  height: 100%;
  .media-content {
    border: 1px solid #cecece;
  }
  .page-bottom {
    margin: 8px 14px;
  }
}
.adas-notice-page {
  user-select: none;
  .ivu-page-next a,
  .ivu-page-prev a {
    color: blue;
    font-size: 18px;
  }
  .ivu-page-disabled a {
    color: #ccc;
  }
  input {
    width: 40px !important;
  }
}
</style>
