<template>
  <el-main class="appContent">
    <div style="height: 100%;width:100%" ref="fullScreenBox">
      <PageView :include="include" :max="100"></PageView>
    </div>
  </el-main>
</template>

<script>
import { mapState } from 'vuex';
import PageView from '@/layout/PageView.vue';
import aeepAlive from "@/router/aeepAlive"

export default {
  name: 'AppMain',
  components: {
    PageView,
  },
  computed: {
    ...mapState(['isFullscreen']),
    /**
     * 需要缓存的页面名称列表'cachePages', 
     */
    include() {
      // console.log(aeepAlive);
      // return this.cachePages.map(menu => menu.name);
      return aeepAlive;
    }
  },
  watch: {
   isFullscreen: {
      handler(v) {
         v ? this.requestFullscreen() : this.exitFullscreen();
      },
      deep: true
   }
  },
  mounted() {
  },
  methods: {
   /**
     * 进入全屏
     */
    requestFullscreen() {
      this.$refs['fullScreenBox'].requestFullscreen();
      // this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      // this.isFullscreen = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.appContent {
  padding: unset;
  background-color: #eceeef;
}
</style>

