<template>
  <div>
    <el-dialog width="710px"
      :visible="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      destroy-on-close
      append-to-body>
      <!-- 标题 -->
      <template #title>
        <div class="header">
          <div class="alarm">
            <img :src="alarmImg" />
            <span>紧急情况！</span>
          </div>
          <!-- 关闭 -->
          <!-- <span class="exit-icon" title="关闭" @click="onClose">
            <ElIcon name="el-icon-close"></ElIcon>
          </span>-->
        </div>
      </template>

      <div class="MainPane">
        <el-carousel ref="Carousel"
          trigger="click"
          :autoplay="false"
          :loop="false"
          arrow="never"
          height="200px">
          <el-carousel-item v-for="(item,index) in dataList"
            :key="index"
            :name="`${index.toString()}`">
            <div class="itemPane"
              v-for="alarm in alarms"
              :key="alarm.key">
              <!-- <label class="pointRed">
                <span></span>
              </label>-->
              <label class="itemTitle">{{alarm.label}}</label>
              <label class="itemText">{{ item[alarm.key] }}</label>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <span slot="footer"
        class="bottom"
        style="margin-top:10px;border-top: 1px solid #eaeefb;padding-top: 10px;">
        <el-pagination :total="tablePage.total"
          :current-page="tablePage.pageNumber"
          background
          prev-text="上一条"
          next-text="下一条"
          :pager-count="5"
          @current-change="changePage"
          :page-size="tablePage.pageSize"
          layout="total, prev, pager, next"></el-pagination>
        <span>
          <el-button type="danger"
            size="mini"
            plain
            @click="onHandleClick(true)"
            class="pl">批量处理</el-button>
          <el-button type="danger"
            size="mini"
            @click="onHandleClick(false)">立即处理</el-button>
        </span>
      </span>
    </el-dialog>

    <el-dialog title="报警处理"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal="false"
      custom-class="custom-class"
      width="50%">
      <div style="padding: 0 20px">
        <p>处理内容</p>
        <el-input type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model.trim="alarmTextarea"></el-input>
        <p>
          <el-radio v-model="alarmRadio"
            label="1">已处理</el-radio>
          <el-radio v-model="alarmRadio"
            label="2">未处理</el-radio>
        </p>
      </div>
      <span slot="footer"
        class="dialog-footer"
        style="display: flex;justify-content: end;border-top: 1px solid #eaeefb;padding-top: 10px;">
        <el-button type="primary"
          @click="handleDealwith">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getWorkloadAlarm } from "@/api/getData";
import { getAddresses } from '@/components/AMap/utils';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';
import { hasPerms, dataPermissions } from "@/utils/auth";

export default {
  name: "cottonPickerAlarm",
  computed: {
    ...mapState(['userInfo', 'appToken']),
  },
  data() {
    this.intervalId = null;
    return {
      visible: false, // 紧急报警模态框状态
      // visible: true, // 紧急报警模态框状态
      handleVisible: false, // 报警处理弹窗
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      dataList: [], // 紧急报警数据
      tablePage: { //分页
        pageNumber: 1,
        pageSize: 1,
        total: 0,
      },
      alarms: [
        { label: '设备名称:', value: '--', key: 'plate' },
        { label: '车 组:', value: '--', key: 'groupName' },
        { label: '告警类型:', value: '--', key: 'type' },
        { label: '告警内容:', value: '--', key: 'copywriting' },
        { label: '时 间:', value: '--', key: 'timeBegin' },
        { label: '地 址:', value: '--', key: 'address' },
      ],
      alarmImg: require("@/assets/screen/alarm.png"),
      isPolling: true,
      alarmRadio: '1',
      alarmTextarea: '',
      dialogVisible: false,
      isBatch: null
    };
  },
  mounted() {
    this.init();
    // this.getAlarmList();
    // this.intervalId = setInterval(async () => {
    //   await this.getAlarmList();
    // }, 1000 * 8);
    // this.handlePolling();
  },
  beforeDestroy() {
    // clearInterval(this.intervalId);
    this.intervalId ? clearInterval(this.intervalId) : '';
    this.isPolling = false;
  },
  destroyed() {
    this.intervalId ? clearInterval(this.intervalId) : '';
    this.isPolling = false;
  },
  deactivated() {
    this.intervalId ? clearInterval(this.intervalId) : '';
    this.isPolling = false;
  },
  // deactivated() {
  //   this.isPolling = false;
  // },
  methods: {
    // 关闭事件
    async onClose() {
      this.visible = false;
    },

    handlePolling() {
      this.intervalId = setTimeout(async () => {
        await this.getAlarmList();
        clearTimeout(this.intervalId);
        this.handlePolling();
      }, 1000 * 30);
    },

    // 获取紧急报警列表
    async getAlarmList() {
      try {
        const { rows, flag, msg, total } = await getVehicleOneKey({ userId: -1 });
        if (flag !== 1) return;

        if (rows.length > 0) {
          this.visible = true;
          this.dataList = rows;
          this.tablePage.total = total;
          let points = rows.map((item, index) => ({ lon: item.lonBegin, lat: item.latBegin, tag: index }));
          this.setAddresses(points, 0);
        } else {
          this.onClose();
        }
      } catch (error) {
        console.error('请求错误');
      }
    },

    // 指令下发后刷新数据
    async onCmdSendChange(type) {
      this.alarmiProp.selects = [];
      this.dataList = [];
      this.visible = false;
    },
    // 指令下发模态框关闭
    closeDialog(model) {
      this.handleVisible = model;
    },
    // 报警处理
    onHandleClick(type) {
      const { dataList } = this;
      if (!dataList.length) return this.$message.error("请选择报警数据");
      this.alarmRadio = '1';
      this.alarmTextarea = '';
      this.isBatch = type;
      // this.alarmiProp.selects = this.dataList;
      this.dialogVisible = true;
      // this.alarmiProp.alarmParmas = {};
    },

    // 点击分页
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.$refs.Carousel.setActiveItem(current - 1);
    },

    // 经纬度转地址
    async setAddresses(points, type) {
      const result = await getAddresses(points);

      result.forEach(({ tag, address }) => {
        this.dataList[tag][["address", "address1"][type]] = address;
      });
      if (result.length) this.dataList = [...this.dataList];

    },
    async init() {
      const that = this;
      if (hasPerms('M01')) {
      this.initPolling();
      const time = setTimeout(() => {
        clearTimeout(time);
        if (that.isPolling) {
           that.init();
         }
      }, 1000 * 30);
      }
    },
    async initPolling() {
      const data = await getWorkloadAlarm();
      this.tablePage.total = data.total;
      // this.dataList = rows;
      if (data && data.rows && data.rows.length > 0) {
        this.dataList = [];
        // this.windowList.map(val=> val.close(val.$el));
        // if (this.windowList.length > 0 && data.rows.map(val=> val.alarmId).includes(this.winList[0].alarmId)) {
        //   return false;
        // }else if(this.windowList.length > 0) {
        //   this.windowList[0].close(this.windowList[0].$el)
        //   this.windowList = [];
        //   this.winList = [];
        // }
        const that = this;
        // const address = await that.getAddressesData([{ lon: data.rows[0].lonBegin, lat: data.rows[0].latBegin, tag: 0 }]);
        // const aa = await VersionWindow({
        //       title: '紧急报警！',
        //       template: "alarmWindow",
        //       message: [{
        //         name: '设备名称：',
        //         value: data.rows[0].plate
        //       },{
        //         name: '车组：',
        //         value: data.rows[0].groupName
        //       },{
        //         name: '告警类型：',
        //         value: data.rows[0].type
        //       },{
        //         name: '告警内容：',
        //         value: data.rows[0].Extend && data.rows[0].Extend.copywriting ? data.rows[0].Extend.copywriting : ''
        //       },{
        //         name: '时间：',
        //         value: data.rows[0].timeBegin
        //       },{
        //         name: '地点：',
        //         value: address
        //       }],
        //       operate: function(e){
        //         that.alarmRadio = '1'
        //         that.alarmTextarea = ''
        //         that.dialogVisible = e
        //         that.currentWindow = data.rows[0]
        //       },
        //       style: {width: '640px'}
        //     })
        // this.windowList.push(aa)
        // this.winList.push(data.rows[0])


        // this.windowList = []
        // if (this.winList.length && this.winList.length > data.rows.length) {
        //   // console.log(32222);
        //   this.winList.map((val, ind)=>{
        //     // console.log(data.rows.find(vla2=> vla2.alarmId !== val.alarmId), ind);
        //     if (data.rows.find(vla2=> vla2.alarmId !== val.alarmId)) {
        //       // console.log(ind);
        //       this.windowList[ind].close(this.windowList[ind].$el);
        //       this.windowList.splice(ind, 1)
        //       this.winList.splice(ind, 1)
        //     }
        //     // console.log(val);
        //     // const winIndex = data.rows.find(vla2=> vla2.alarmId !== val.alarmId)
        //     // console.log(winIndex);
        //   })
        //   // closeWin.reverse().map(item=> {
        //   //   this.windowList.splice(item, 1)
        //   //   this.winList.splice(item, 1)
        //   // })
        // }
        data.rows.map(async (val, ind) => {
          val.copywriting = val.Extend && val.Extend.copywriting ? val.Extend.copywriting : '';
          val.address = await that.getAddressesData([{ lon: val.lonBegin, lat: val.latBegin, tag: ind }]);
          this.dataList.push(val);
          //   if (!this.winList.map(item=> JSON.stringify(item)).includes(JSON.stringify(val))) {
          //     const aa = await VersionWindow({
          //       title: '紧急报警！',
          //       template: "alarmWindow",
          //       message: [{
          //         name: '设备名称：',
          //         value: val.plate
          //       },{
          //         name: '车组：',
          //         value: val.groupName
          //       },{
          //         name: '告警类型：',
          //         value: val.type
          //       },{
          //         name: '告警内容：',
          //         value: val.Extend && val.Extend.copywriting ? val.Extend.copywriting : ''
          //       },{
          //         name: '时间：',
          //         value: val.timeBegin
          //       },{
          //         name: '地点：',
          //         value: val.address
          //       }],
          //       operate: function(e){
          //         that.alarmRadio = '1'
          //         that.alarmTextarea = ''
          //         that.dialogVisible = e
          //         that.currentWindow = val
          //       },
          //       style: {width: '640px'}
          //     })
          //     this.winList.push(val);
          //     this.windowList.push(aa);
          //   }else {
          //     // if (!this.winList.map(item2=> JSON.stringify(item2)).includes(JSON.stringify(val))) {
          //     // console.log(this.winList.map(item2=> JSON.stringify(item2)).includes(JSON.stringify(item)));
          //     // console.log(this.winList.findIndex(item=> JSON.stringify(item) == JSON.stringify(this.winList.find(item=> this.winList.map(item2=> JSON.stringify(item2)).includes(JSON.stringify(item))))));

          //     //   // const winIndex = this.winList.findIndex(item3=>!this.winList.map(item2=> JSON.stringify(item2)).includes(JSON.stringify(val)))
          //     //   console.log(winIndex);
          //     //   closeWin.push(winIndex);
          //     //   this.windowList[winIndex].close(this.windowList[winIndex].$el);
          //     //   this.windowList.map(val=> val.close(val.$el));
          //     // }
          //   }
        });
        // 
        // if (this.dialogVisible) {
        //   this.dialogVisible = false;
        //   setTimeout(()=>{
        //     this.dialogVisible = true
        //   },500)
        // }
        // }
        // else if(this.windowList.length > 0) {
        //   this.windowList[0].close(this.windowList[0].$el)
        //   this.windowList = [];
        //   this.winList = [];
        this.visible = true;
      } else {
        this.visible = false;
      }

    },
    async getAddressesData(points) {
      const result = await getAddresses(points);
      return result.length > 0 ? result[0].address : '';
    },
    handleDealwith() {
      const that = this;
      if (!this.alarmTextarea) {
        this.$message.error('请输入处理内容');
        return false;
      }

      const cmdParamsBoday = {
        isAuto: 0, // 处理方式 0： 人工处理
        handleType: this.alarmRadio == '1' ? 32 : 0,
        // handleTemplate, // 报警处理模板, 短信有效
        // loginName: this.userInfo.name,
        userId: this.userInfo.userId,
        handleContent: this.alarmTextarea,
        // handler: handler,
        // remark: remark,
      };

      let cmdParams = [];
      if (this.isBatch) {
        cmdParams = this.dataList.map(val => {
          return {
            alarmId: val.alarmId,
            groupId: val.groupId,
            vehicleId: val.vehicleId,
            alarmType: val.alarmType,
            extend: {
              msgSendAddr: val.phone,
              plate: val.plate,
              alarmAddress: val.address,
              alarmTime: val.timeBegin
            }
          };
        });
      } else {
        cmdParams = [{
          alarmId: this.dataList[this.tablePage.pageNumber - 1].alarmId,
          groupId: this.dataList[this.tablePage.pageNumber - 1].groupId,
          vehicleId: this.dataList[this.tablePage.pageNumber - 1].vehicleId,
          alarmType: this.dataList[this.tablePage.pageNumber - 1].alarmType,
          extend: {
            msgSendAddr: this.dataList[this.tablePage.pageNumber - 1].phone,
            plate: this.dataList[this.tablePage.pageNumber - 1].plate,
            alarmAddress: this.dataList[this.tablePage.pageNumber - 1].address,
            alarmTime: this.dataList[this.tablePage.pageNumber - 1].timeBegin
          }
        }];
      }
      // const cmdParams = [{
      //       alarmId: this.dataList[this.tablePage.pageNumber -1].
      //       alarmId,
      //       groupId: this.dataList[this.tablePage.pageNumber -1].groupId,
      //       vehicleId: this.dataList[this.tablePage.pageNumber -1].vehicleId,
      //       alarmType: this.dataList[this.tablePage.pageNumber-1].alarmType,
      //       extend: {
      //         msgSendAddr: this.dataList[this.tablePage.pageNumber-1].phone,
      //         plate: this.dataList[this.tablePage.pageNumber-1].plate,
      //         alarmAddress: this.dataList[this.tablePage.pageNumber-1].address,
      //         alarmTime: this.dataList[this.tablePage.pageNumber-1].timeBegin
      //       }
      //     }];
      // 组装下发消息体
      const cmdData = {
        ...cmdParamsBoday,
        param: cmdParams
      };



      const cmdtype = CmdType.generalAlarmProcess;
      //  CmdType.generalAlarmProcess;

      // TODO: 是否需要等待回复
      cmdWsObj.request.sendCmdAsync(cmdtype, cmdData).then(res => {
        that.initPolling();
      });
      // setTimeout(()=>{
      //   this.initPolling()
      // },1200)
      this.dialogVisible = false;
      // this.windowList[0].close()
      // this.$message.error('暂无接口---')
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-dialog {
    margin: initial !important;
    border-radius: 8px;
    .el-dialog__headerbtn {
      top: 12px;
    }
    .el-dialog__header {
      padding: 10px;
      border-bottom: 1px solid #e8eaec;
      font-weight: 400;
      background: #9e0000;
      font-size: 16px;
      color: #ffffff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .alarm {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
    }

    .el-dialog__footer {
      top: initial;
      padding: 5px;
      text-align: center !important;
    }

    .el-dialog__body {
      padding: initial;
    }
  }
}
::v-deep .el-pagination__jump {
  margin-left: 0;
}
.header {
  position: relative;
}
.exit-icon {
  position: absolute;
  right: 0px;
  top: -2px;
  cursor: pointer;
  width: 20px;
  text-align: center;
}
.bottom {
  position: relative;
  height: 35px;
  width: 100%;
  display: inline-flex;
  // justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  & > button {
    margin: 0 3px;
  }
}

.header {
  display: flex;
  align-items: center;
  height: 54px;
  background-color: rgba(158, 0, 0, 1);
  span {
    font-size: 22px;
    color: #fff;
  }
  img {
    // height: 30px;
    width: 30px;
    margin: 0 16px 0 22px;
  }
}
.MainPane {
  width: 100%;
  height: auto;
  padding: 0 10px;
  box-sizing: border-box;
}
.itemPane {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  & > label {
    margin: 0 6px;
    display: block;
  }
}
.pointRed {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px #9e0000 solid;
  & > span {
    display: block;
    width: 6px;
    height: 6px;
    background: #9e0000;
    border-radius: 50%;
    margin: 2px auto;
  }
}
.itemTitle {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;
  // width: 88px;
  text-align: right;
}
.itemText {
  flex: 1;
  line-height: 24px;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #fff;
}
.item-body {
  padding: 20px;
  background-color: #fff;
}
::v-deep .el-button--danger {
  background: #9e0000;
}
.pl {
  background: #fff;
  color: #9e0000;
  border-color: #9e0000;
}
::v-deep .el-pagination {
  button {
    padding: 0 10px !important;
  }
  // .btn-prev {
  //   >sapn {
  //     padding: 0 10px;
  //   }
  // }
}
// .dialog-footer {
//   display: flex;
//   justify-content: end;
// }
::v-deep .custom-class {
  .el-dialog__header {
    padding: 16px;
    background-color: #212d3e !important;
    .el-dialog__title {
      color: #fff;
    }
  }
  .el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  p {
    margin: 20px 0;
  }
}
::v-deep .el-carousel__indicators {
  display: none;
}
</style>