<template>
  <Iconfont class="avatorImg" :size="22" :name="vehicleIconName()"></Iconfont>

  <!-- <img class="avatorImg" :src="avatorImg" /> -->
</template>

<script>
// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import vehiclePicture from '@/utils/vehiclePicture';


export default {
  name: 'UserAvator',
  props: {
    data: {
      type: Object,
      default() {
        return [];
      }
    }
  },
  computed: {
    avatorImg() {
      return require('@/assets/images/车辆头像.png');
    }
  },
  methods: {
    vehicleIconName() {
      return vehiclePicture(this.data.machineryProductType, this.data.machineryEquipmentType, (this.data.Z || this.data.vehicleStatus));
    },
  }
}
</script>

<style lang="scss" scoped>
.avatorImg {
  position: absolute;
  left: 0;
  width: 19px;
  height: 12px;
}
</style>