<template>
  <el-header class="appHeader">
    <!-- LOGO -->
    <LOGO></LOGO>
    <!-- 下拉导航 -->
    <NavDropdown ></NavDropdown>
    <!-- 头部导航 -->
    <HeaderNav
      :navData="headerNavs"
      :activeMenu="activeRoute"
      @click="goRoute"
      @close="onCloseRoute"
    ></HeaderNav>
    <!-- 提醒 -->

    <!-- 到期提醒 -->
    <ExpiredReminder></ExpiredReminder>

    <!--报警-->
    <AlarmIcon />
    <!-- 头像，更多 -->
    <HeaderMore></HeaderMore>
    <!-- 全屏 -->
    <Fullscreen></Fullscreen>

    <UrgentAlarm />

    <cotton-picker-alarm />
  </el-header>
</template>

<script>
//
import { mapState, mapMutations } from 'vuex';
import NavDropdown from '@/layout/NavDropdown';
import LOGO from './Logo.vue';
import HeaderNav from '@/layout/HeaderNav';
import { hasPerms } from '@/utils/auth';

import Fullscreen from '@/components/Icon/FullScreen';
import AlarmIcon from './AlarmIcon.vue';
import HeaderMore from './HeaderMore.vue';
import UrgentAlarm from './UrgentAlarm.vue';
import cottonPickerAlarm from './cottonPickerAlarm.vue';

//到期提醒
import ExpiredReminder from '@/layout/Header/ExpiredReminder.vue';

export default {
  name: 'AppHeader',
  components: {
    LOGO,
    NavDropdown,
    HeaderNav,
    Fullscreen,
    AlarmIcon,
    HeaderMore,
    ExpiredReminder,
    UrgentAlarm,
    cottonPickerAlarm
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['headerMenus','userInfo']),
    // , 'cachePages'
    headerNavs() {
      const list = this.headerMenus || [];
      return list.filter(item => hasPerms(item.meta.permId));
    },
    /**
     * 当前活动页路由
     */
    activeRoute() {
      const { headerNavs } = this;

      const { matched } = this.$route;
      // 找到匹配的路由
      const menu = this.findSameRoute(headerNavs, matched);
      return menu || this.$route;
    },
    /**
     * 判断当前活动页是否渲染到头部
     */
    inHeaders() {
      const { headerNavs, activeRoute } = this;
      return headerNavs.some(obj => obj.name === activeRoute.name);
    },

  },

  methods: {
    ...mapMutations(['setHeaderMenus', 'setCachePages']),
    /**
     * 路由跳转
     */
    goRoute(nav) {
      this.$router.push(nav.path);
    },
    /**
     * 头部项关闭事件
     */
    onCloseRoute(nav) {
      let { headerMenus, cachePages } = this;
      // 从headerMenus清除
      headerMenus = headerMenus.filter(menu => menu.name !== nav.name);
      this.setHeaderMenus(headerMenus);

      // 从缓存页面清除
      // cachePages = cachePages.filter(menu => menu.name !== nav.name);
      // this.setCachePages(cachePages);

      // // 关闭当前页后, 默认路由到最新打开的页面
      // if (cachePages.length) {
      //   const menus = this.filterSameRoutes(cachePages, headerMenus);
      //   if (menus.length) {
      //     this.$router.push(menus[menus.length - 1].path);
      //     return;
      //   }
      // }
      // 路由到默认页面
      this.$router.push('/');
    },
    /**
     * 从`source`中找到与`target`匹配的项
     */
    findSameRoute(source = [], target = []) {
      return source.find(obj => target.some(obj1 => obj1.name === obj.name));
    },
    /**
     * 从`source`筛选出所有匹配`target`的项
     */
    filterSameRoutes(source = [], target = []) {
      return source.filter(obj => target.some(obj1 => obj1.name === obj.name));
    },


  }
}
</script>

<style lang="scss" scoped>
.appHeader {
  background-color: #212d3e;
  display: flex;
  padding-left: 0;
}
</style>