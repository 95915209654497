<template>
  <div class="HeaderMore">
    <el-dropdown @command="onCommandClick" placement="bottom-start">
      <!-- <div class="icon-pane">
        <Iconfont name="icon-gengduo" :size="20"></Iconfont>
        <span>更多</span>
      </div>-->
      <AvatarWithText
        @click.native="a"
        :url="userInfo.picture"
        :text="userInfo.name"
      ></AvatarWithText>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-if="hasPerms('M17')" :command="1">
          <Iconfont name="icon-xitongshezhi" :mRight="7"></Iconfont>
          <span>系统设置</span>
        </el-dropdown-item>
        <!-- <el-dropdown-item v-if="hasPerms('M18')" :command="2">
          <Iconfont name="icon-dapingzhanshi" :mRight="7"></Iconfont>
          <span>大屏展示</span>
        </el-dropdown-item>-->
        <el-dropdown-item v-if="hasPerms('M15')" :command="3">
          <Iconfont name="icon-gerenxinxi2" :mRight="7"></Iconfont>
          <span>个人信息</span>
        </el-dropdown-item>
        <el-dropdown-item v-if="hasPerms('M16')" :command="4">
          <Iconfont name="icon-xiugaimima1" :mRight="7"></Iconfont>
          <span>修改密码</span>
        </el-dropdown-item>
        <el-dropdown-item :command="0" divided>
          <Iconfont name="icon-tuichu" :mRight="7"></Iconfont>
          <span>退出系统</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>

/**
 * 更多按钮
 */

import { mapMutations, mapState } from 'vuex';
import { MsgBox } from '@/utils/msgBox';
import AvatarWithText from './AvatarWithText.vue';
import * as SystemCenter from '@/router/modules/SystemCenter';
import * as BigDataCenter from '@/router/modules/BigDataCenter';
import { hasPerms } from '@/utils/auth';


export default {
  name: 'HeaderMore',
  components: { AvatarWithText },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    ...mapMutations(['setHeaderMenus']),
    hasPerms,
    onCommandClick(command) {
      switch (command) {
        case 0:
          this.logout();
          break;
        case 1: // 系统设置
          this.goRoute(SystemCenter.SystemSettings);
          break;
        case 2: // 大屏展示
          this.goRoute(BigDataCenter.BigScreenDisplay);
          break;
        case 3: // 个人信息
          this.goRoute(SystemCenter.PersonalInformation);
          break;
        case 4: // 安全设置 修改密码
          this.goRoute(SystemCenter.SecuritySettings);
          break;
      }
    },

    /**
     * 退出提示
     */
    async logout() {
      if (await MsgBox.exit()) {
        this.$router.push('/login');
      }
    },

    /**
     * 路由跳转
     */
    goRoute(nav) {
      this.$router.push(nav.path);

      // 判断是否已经打开, 否则存储`headerMenus`中
      const { headerMenus } = this.$store.state;
      if (!headerMenus.some(menu => menu.name === nav.name)) {
        headerMenus.push(nav);
        this.setHeaderMenus(headerMenus);
      }
    },
    a() {
      
    }
  }
}
</script>

<style lang="scss" scoped>
.HeaderMore {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon-pane {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
}
</style>