var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ExpiredReminder reportDialog"},[_c('el-dialog',{attrs:{"title":"平台到期提醒","width":"1200px","visible":_vm.dialogVisible,"close-on-click-modal":false,"close-on-press-escape":false},on:{"close":_vm.dialogClose}},[_c('el-container',{staticStyle:{"height":"400px"}},[_c('el-header',{staticStyle:{"height":"40px","padding":"0"}},[_c('el-row',[_c('el-col',{attrs:{"span":7}},[_c('label',{staticStyle:{"margin-right":"5px"}},[_vm._v("过期时间")]),_c('el-date-picker',{attrs:{"type":"date","value-format":"yyyy-MM-dd"},model:{value:(_vm.reminderDate),callback:function ($$v) {_vm.reminderDate=$$v},expression:"reminderDate"}})],1),_c('el-col',{attrs:{"span":11}},[_c('el-radio-group',{on:{"change":_vm.radioChange},model:{value:(_vm.dateTypeDate),callback:function ($$v) {_vm.dateTypeDate=$$v},expression:"dateTypeDate"}},_vm._l((_vm.dateType),function(item){return _c('el-radio-button',{key:item.value,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1),_c('el-col',{attrs:{"span":2}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onSearch(_vm.dateTypeDate,1)}}},[_vm._v("搜索")])],1),_c('el-col',{attrs:{"span":2}},[_c('el-button',{on:{"click":_vm.openAuthCoin}},[_vm._v("批量授权")])],1),_c('el-col',{attrs:{"span":2}},[_c('BtnExport',{staticStyle:{"width":"90px","height":"32px"},attrs:{"disabled":!_vm.data.length>0,"title":"到期提醒","exportUrl":"remindExpire/exportVehicleRemindExcel.do","exportDataType":"1","params":{
                remindType:3,
                endTime:this.reminderDate,
                state:0,
                userId:1,
                pageSize:5000,
              }}})],1)],1)],1),_c('el-main',{staticStyle:{"height":"calc(100% - 40px)","padding":"0 0px 5px 0px"}},[_c('el-table',{attrs:{"data":_vm.data,"size":"mini","height":"calc(100% - 32px)"},on:{"selection-change":_vm.changeTableData}},[_c('el-table-column',{attrs:{"type":"selection","width":"50px"}}),_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50px"}}),_c('el-table-column',{attrs:{"label":"操作","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.rechargeIng(scope.row)}}},[_vm._v("授权")])]}}])}),_c('el-table-column',{attrs:{"label":"设备名称","prop":"plate","width":"100px"}}),_c('el-table-column',{attrs:{"label":"颜色","prop":"plateColor","width":"80px"}}),_c('el-table-column',{attrs:{"label":"车组","prop":"groupName"}}),_c('el-table-column',{attrs:{"label":"终端号","prop":"terminalNo","width":"120px"}}),_c('el-table-column',{attrs:{"label":"终端类型","prop":"terminalType","width":"120px"}}),_c('el-table-column',{attrs:{"label":"SIM","prop":"sim"}}),_c('el-table-column',{attrs:{"label":"到期时间","prop":"expireTime"}}),_c('el-table-column',{attrs:{"label":"安装时间","prop":"installDate"}})],1),_c('el-pagination',{staticStyle:{"text-align":"center"},attrs:{"background":"","layout":"total, prev, pager, next, jumper","current-page":_vm.tablePage.pageNumber,"page-size":_vm.tablePage.pageSize,"total":_vm.tablePage.total},on:{"current-change":_vm.changePage}})],1)],1)],1),_c('AuthCoin',{attrs:{"dialogVisible":_vm.authCoinDialog,"vehicleList":_vm.vehicleList},on:{"input":_vm.closeDialog}}),_c('div',{staticClass:"icon-pane",on:{"click":_vm.dialogVisibleClick}},[_c('el-badge',{directives:[{name:"show",rawName:"v-show",value:(_vm.badgeRad),expression:"badgeRad"}],staticClass:"item badgeRad",attrs:{"is-dot":""}}),_c('Iconfont',{attrs:{"name":"icon-xiaoxitongzhi","size":21}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }