/**
 * 下载文件
 */


import { saveAsFile } from './saveAsFile';

export const downloadFile = (url, filename) => {
  return fetch(url, {
    // headers: new Headers({
    //   Origin: location.origin,
    // }),
    // credentials: 'omit',
    mode: 'cors',
    cache: 'no-cache',
  })
    .then(res => res.blob())
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob);
      saveAsFile(blobUrl, filename);
      URL.revokeObjectURL(blobUrl);
    });
};