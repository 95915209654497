<template>
  <div class="AlarmTabs">
    <el-tabs v-model="activeName"
      @tab-click="handleClick">
      <!-- <el-tab-pane label="主动安全防控报警"
        name="1"></el-tab-pane> -->
      <el-tab-pane label="异常告警" v-if="handleHasPerms('M09SM04-abnormal')"
        name="0"></el-tab-pane>
      <el-tab-pane label="常规告警(安全风险)" v-if="handleHasPerms('M09SM04-routine')"
        name="1">{{' '}}</el-tab-pane>
      <el-tab-pane label="常规告警(设备风险)" v-if="handleHasPerms('M09SM04-routine')"
        name="2">{{' '}}</el-tab-pane>
    </el-tabs>

    <div class="table-pane">
      <div class="search-item">
         <span style="margin-right: 2px">告警名称：</span>
          <el-select v-model="conditionObj.alarmTypeId" clearable placeholder="请选择">
            <el-option
               v-for="item in alarmTypeOptions[activeName]"
               :key="item.value"
               :label="item.text"
               :value="item.value">
            </el-option>
         </el-select>
         <span style="margin-right: 2px;margin-left: 12px">告警等级：</span>
          <el-select v-model="conditionObj.level" clearable placeholder="请选择">
            <el-option
               v-for="item in levelOptions"
               :key="item.value"
               :label="item.text"
               :value="item.value">
            </el-option>
         </el-select>
         <div style="width: 12px"></div>
        <SelectGroupTree :multipleProp="{ noGroup: true }"
          ref="groupRef"
          class="select"
          :clearable="true"
          @dbClick="dbChange"
          @on-select="onSelect"
          @on-vehicle="onConfirm"
          @clear="clearClick" />
        <el-button type="primary"
          @click="seachClick">搜索</el-button>
        <el-button type="primary"
          @click="onBatchClick">批量处理</el-button>
      </div>
      <el-table ref="refTable"
        v-loading="tableLoading"
        size="mini"
        border
        height="87%"
        :header-cell-style="{backgroundColor: '#F0F4FB',fontWeight: 'bold',color: '#333333'}"
        :data="dataList"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection"
          :selectable="onTableSelectable"
          width="55"></el-table-column>
        <el-table-column type="index"
          width="50"
          label="序号"></el-table-column>
        <el-table-column prop="plate"
          width="160"
          label="设备名称"></el-table-column>
        <el-table-column prop="type"
          width="160"
          label="报警名称"></el-table-column>
        <el-table-column prop="grou2pName"
          width="160"
          label="报警等级">
          <template v-slot="{ row }">
            {{ row.levelStr ? row.levelStr : '--' }}
         </template>
         </el-table-column>
        <el-table-column prop="alarmSource"
          width="160"
          label="报警来源">
          <template v-slot="{row}">{{ row.alarmSource === 1 ? '车载设备' : '平台触发'}}</template>
        </el-table-column>
        <el-table-column prop="address"
          min-width="330"
          label="告警地址"></el-table-column>
        <el-table-column prop="timeBegin"
          width="160"
          label="报警开始时间"></el-table-column>
        <el-table-column prop="machineryProductType"
          width="160"
          label="设备类型"></el-table-column>
        <el-table-column prop="machineryEquipmentType"
          width="160"
          label="设备种类"></el-table-column>
        <el-table-column prop="groupName"
          width="160"
          label="所属车组"></el-table-column>

        <el-table-column width="160" label="操作">
          <template v-slot="{row}">
            <el-button type="text" @click="handleShowDetails(row)">报警详情</el-button>
            <el-button :disabled="row.alarmIsHandle == 1" v-if="!row.isCommercialVehicles"
              type="text"
              @click="onHandleClick(row)">{{row.alarmIsHandle == 1 ? '已处理': '处理'}}</el-button>
          </template>
        </el-table-column>

        <el-table-column min-width="110"
          label="操作" v-if="false">
          <!-- v-if="activeName==='1'" -->
          <template v-slot="{ row }">
            <el-button type="text"
              :disabled="!row.aviPath.length && !row.path.length"
              @click="downLoadClick(row)">下载</el-button>

            <!-- <el-popover
              :value="row === viewImgRow"
              placement="right"
              width="500"
              trigger="manual"
            >
              <div class="popoverContent">
                <div class="popoverContentHeader">
                  <span class="title">查看附件</span>

                  <span class="CloseIcon" title="关闭" @click="viewImgRow=null, showSingleMeida=[]">
                    <ElIcon name="el-icon-close" :size="18"></ElIcon>
                  </span>
                </div>
                <DrivingMediaPage
                  v-if="showSingleMeida.length"
                  v-bind:config="config"
                  v-bind:adasData="showSingleMeida"
                  class-name="drivingMediaPageClass"
                ></DrivingMediaPage>
                <el-empty description="暂无附件" v-else></el-empty>
              </div> -->

            <el-button :disabled="!row.aviPath.length && !row.path.length"
              slot="reference"
              type="text"
              @click="clickShowImg(row)">查看</el-button>
            <!-- </el-popover> -->
          </template>
        </el-table-column>
          
        <!-- <el-table-column prop="type"
          width="160"
          label="报警类型"></el-table-column>
        <el-table-column prop="isAcc"
          width="160"
          label="状态"
          v-if="activeName == 1">
          <template v-slot="{ row }"> {{ row.data && row.data.isAcc ? '关机' : '开机'  }}</template>
        </el-table-column>
        <el-table-column prop="timeEnd"
          width="160"
          label="报警结束时间"></el-table-column>
        <el-table-column prop="durationStr"
          width="100"
          label="持续时间"></el-table-column>
        <el-table-column prop="speedBegin"
          width="100"
          label="速度 km/h"></el-table-column> -->
      </el-table>

      <el-pagination background
        :total="tablePage.total"
        :current-page="tablePage.pageNumber"
        @current-change="changePage"
        :page-size="tablePage.pageSize"></el-pagination>

      <el-dialog title="查看附件"
        :visible.sync="dialogVisible"
        append-to-body
        width="500px">
        <DrivingMediaPage v-if="showSingleMeida.length && dialogVisible"
          v-bind:config="config"
          v-bind:adasData="showSingleMeida"
          class-name="drivingMediaPageClass"></DrivingMediaPage>
        <el-empty description="暂无附件"
          v-else></el-empty>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span> -->
      </el-dialog>
    </div>

    <Dialog @on-cmdSend="onCmdSendChange"
      :visible="visibleDialog"
      :iProp="alarmiProp"
      @input="closeDialog" />
    <details-dialog v-model="isShowDetails" :isDownload="false" :details="detailsData" @onHandleClick="onHandleClick(detailsData)"
      @changeList="seachClick" />
    <alarmDetailsDialog  v-model="isVisible"
      :editDetail="detailsData" />
  </div>
</template>

<script>
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import { getAdasAlarmToday, getFCWAlarmToday, downloadAlarmEvidence } from '@/api/adasReport-api';
import moment from 'dayjs';
import { getAddresses } from '@/components/AMap/utils';
import DrivingMediaPage from "@/components/DrivingMediaPage";
import { appConfig } from '@/config/appConfig';
import { saveAsFile } from '@/utils/saveAsFile';
import Dialog from '@/views/SecurityMonitoring/StatisticalReport/components/DealWithDialog.vue';
import DetailsDialog from '@/views/SecurityMonitoring/StatisticalReport/components/DetailsDialog.vue';
import { tblAlarmdefineListOfWarningLight } from "@/api/getData.js";
import { hasPerms, dataPermissions } from "@/utils/auth";
import alarmDetailsDialog from "@/views/RealTimePositioning/components/alarmDetailsDialog";

export default {
  name: "AlarmTabs",
  components: {
    SelectGroupTree,
    DrivingMediaPage,
    Dialog,
    DetailsDialog,
    alarmDetailsDialog
  },
  data() {
    this.intervalId = null;
    return {
      alarmNum: 0, // 报警总数量
      visible: false, // 弹框状态
      activeName: hasPerms('M09SM04-abnormal') ? '0' : '1', // 报警类型
      conditionObj: this.initParams(),
      tableLoading: false,
      tablePage: { //分页
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },

      dataList: [],
      showSingleMeida: [],

      config: {
        baseURL: appConfig.baseMediaUrl,
        userId: "",
        userkey: ""
      },

      visibleDialog: false, // 报警处理模态框 
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      // 当前查看附件的数据
      viewImgRow: null,
      dialogVisible: false,
      levelOptions: [
            //   { value: "1", text: "低" },
              { value: "2", text: "中" },
              { value: "3", text: "高" },
            ],
      alarmTypeOptions: {
         '0': [],
         '1': [],
         '2': [],
      },
      detailsData: {},
      isShowDetails: false,
      isVisible: false,
    };
  },
  mounted() {
    // this.getAlarmTodayList(this.activeName * 1);
      tblAlarmdefineListOfWarningLight().then((res) => {
        if (res && res.obj) {
         this.alarmTypeOptions['0'] = res.obj.commonOfAbnormal.map(val=>{
            return {
               text: val.alarmname,
               value: val.alarmId,
            };
         });
         this.alarmTypeOptions['1'] = res.obj.adas.map(val=>{
            return {
               text: val.alarmname,
               value: val.alarmId,
            };
         });;
         this.alarmTypeOptions['2'] = res.obj.commonOfGeneral.map(val=>{
            return {
               text: val.alarmname,
               value: val.alarmId,
            };
         });;
         // this.alarmTypeOptions = res.obj.map(val=>{
         //    return {
         //       text: val.alarmname,
         //       value: val.alarmId,
         //    };
         // })
        }
      })
  },

  methods: {
    // 下载文件
    downLoadClick(row) {
      downloadAlarmEvidence({
        alarmId: row.alarmId,
        startTime: row.startTime,
        endTime: row.endTime
      }).then(res => {
        let urls = URL.createObjectURL(res);
        saveAsFile(urls, `${ moment(new Date()).format("YYYYMMDDHHmmss") }.zip`);
      });

    },

    clearClick() {
      this.conditionObj.flag = 1;
      this.conditionObj.vehicleId = 0;
      this.conditionObj.groupId = 0;
    },
    // 关闭方法
    closeClick() {
      this.activeName = '0';
      this.$refs.groupRef.channelClick();
      this.clearClick();
      this.viewImgRow = null;
    },

    // 模态框关闭
    closeDialog(model) {
      this.visibleDialog = model;
    },

    // 报警处理 -- 单个 
    onHandleClick(row) {
      this.alarmiProp.selects = [];
      this.visibleDialog = true;
      this.alarmiProp.alarmParmas = {
        plate: row.plate, // 设备名称
        vehicleId: row.vehicleId, // 车辆Id
        groupName: row.groupName, // 车组名称
        groupId: row.groupId, // 车组Id
        alarmTime: row.timeBegin, // 报警时间
        alarmTypeEn: row.alarmTypeEn,
        alarmType: row.alarmType, // 报警类型
        alarmTypeCh: row.type,
        alarmAddress: row.address, // 报警地址
        alarmSourceStr: { 1: '车载终端', 2: '企业监控平台', 3: '政府监管平台', 9: '其他' }[row.alarmSource],
        alarmSource: row.alarmSource, // 报警来源
        alarmId: row.alarmId
      };
    },

    // 报警处理 -- 批量
    onBatchClick() {
      const { selects } = this.alarmiProp;
      if (!selects.length) return this.$message.error("请选择报警数据");

      this.alarmiProp.alarmParmas = {};
      this.visibleDialog = true;
    },

    // 勾选table数据
    handleSelectionChange(val) {
      this.alarmiProp.selects = val.filter(item=> !item.isCommercialVehicles);
    },

    /** 点击查看图片 */
    clickShowImg(data) {
      this.viewImgRow = data;
      this.showSingleMeida = [];
      let m_id = 1;
      data.path.forEach(element1 => {
        let temp = {
          id: m_id,
          device: data.terminalNo,
          plate: data.plate,
          media: 4,
          alarm: data.adasAlarmType,
          time: data.startTime,
          starttime: Math.round(new Date(data.startTime).getTime() / 1000),
          endtime: Math.round(new Date(data.endTime).getTime() / 1000),
          lat: data.lat,
          lon: data.lon,
          ftpServerPath: data.ftpServerPath,
          rootPath: data.rootPath,
          filefullname: element1,
          threshold:
            data.similarityDegree &&
            data.similarityDegree.length &&
            data.similarityDegree[0],
          result: data.result && data.result.length && data.result[0],
          comptrastType:
            data.comptrastType &&
            data.comptrastType.length &&
            data.comptrastType[0]
        };
        m_id = m_id + 1;
        this.showSingleMeida.push(temp); //圖片
      });

      if (data.driverPath) {
        data.driverPath.forEach((element3, index) => {
          let temp = {
            id: m_id,
            plate: data.plate,
            alarm:
              data.adasAlarmType +
              "【相似度：" +
              (data.similar[index] * 1).toFixed(2) +
              "%】",
            time: data.startTime,
            media: 4,
            rootPath: data.rootPath,
            ftpServerPath: data.ftpServerPath,
            filefullname: element3,
            threshold:
              data.similarityDegree &&
              data.similarityDegree.length &&
              data.similarityDegree[0],
            result: data.result && data.result.length && data.result[0],
            comptrastType:
              data.comptrastType &&
              data.comptrastType.length &&
              data.comptrastType[0]
          };
          m_id = m_id + 1;
          this.showSingleMeida.push(temp); //視頻
        });
      }
      if (data.aviPath) {
        data.aviPath.forEach(element2 => {
          let temp = {
            id: this.showSingleMeida.length,
            device: data.terminalNo,
            plate: data.plate,
            media: 3,
            alarm: data.adasAlarmType,
            time: data.startTime,
            starttime: Math.round(new Date(data.startTime).getTime() / 1000),
            endtime: Math.round(new Date(data.endTime).getTime() / 1000),
            lat: data.lat,
            lon: data.lon,
            ftpServerPath: data.ftpServerPath,
            rootPath: data.rootPath,
            filefullname: element2
          };
          m_id = m_id + 1;
          this.showSingleMeida.push(temp); //視頻
        });
      }
      this.dialogVisible = true;
    },

    initParams() {
      return {
        startTime: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        endTime: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        vehicleId: 0,
        groupId: 0,
        level: '',
        alarmTypeId: '',
        alarmTypes: [],
        flag: 1, // 查询类型 0：车组 1：车辆
      };
    },

    // 搜索事件
    seachClick() {
      this.search(this.activeName * 1);
    },

    search(tabsName) {
      this.tablePage.total = 0;
      this.tablePage.pageNumber = 1;
      this.getAlarmTodayList(tabsName);
    },

    // 获取当日报警数据
    async getAlarmTodayList(tabsName) {
      this.dataList = [];
      this.tableLoading = true; // table加载中
      // let alarmTypes = !tabsName ? GeneralAlarmTypes : AdasAlarmTypes; //  获取对应的报警类型
      let params = {  // 构建入参
        ...this.conditionObj,
        isHandler: 1, // 过滤已处理报警
        pageNumber: this.tablePage.pageNumber,
        pageSize: this.tablePage.pageSize,
        category: tabsName ? 0 : 1
      };
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      let action = tabsName != 1 ? getFCWAlarmToday : getAdasAlarmToday;
      const result = await action(params);

      loading.close();
      this.tableLoading = false;

      if (!(result.flag && result.rows)) return this.$message.warning(result.msg);

      this.tablePage.total = result.total;
      this.dataList = result.rows.map(val => {
        try {
          val.data && this.activeName == 1 ? val.data = JSON.parse(val.data) : '';
        } catch (error) {
          console.log(error);
        }
        return val;
      });

      if (tabsName) { // adas报警数据映射
        this.dataList = this.dataList.map(item => ({
          ...item,
          type: item.adasAlarmType ? item.adasAlarmType : item.type,
          timeBegin: item.timeBegin ? item.timeBegin : item.startTime,
          timeEnd: item.endTime,
          speedBegin: item.speed,
        }));
      }

      let points = result.rows.map((item, index) => ({ lon: item.lonBegin, lat: item.latBegin, tag: index }));
      this.setAddresses(points, 0);
    },

    async setAddresses(points, type) {
      const result = await getAddresses(points);
      result.forEach(({ tag, address }) => {
          this.dataList[tag][["address", "address1"][type]] = address;
      });
      if (result.length) this.dataList = [...this.dataList];
    },

    // 标签页切换事件
    handleClick(tab) {
      this.tablePage.pageNumber = 1;
      this.tablePage.total = 0;
      const { name } = tab;
      this.alarmiProp.type = name * 1;
      this.getAlarmTodayList(name * 1);
    },

    // 指令下发后刷新数据
    onCmdSendChange(type) {
      this.activeName = String(type);
      this.getAlarmTodayList(type);
    },

    // 双击车组节点
    dbChange(node) {
      this.conditionObj.flag = 0;
      this.conditionObj.vehicleId = 0;
      this.conditionObj.groupId = node.groupId;
    },

    // 车辆搜索框选择事件
    onSelect(node) {
      this.conditionObj.vehicleId = node.vehicleId || 0;
      this.conditionObj.groupId = node.groupId || 0;
      if (this.conditionObj.vehicleId === 0) return this.conditionObj.flag = 0;
    },

    // 选择车辆事件
    onConfirm(node) {
      this.conditionObj.flag = 1;
      this.conditionObj.vehicleId = node.vehicleId || 0;
      this.conditionObj.groupId = node.groupId || 0;
    },

    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.getAlarmTodayList(this.activeName * 1);
    },

    // 设置复选框禁选状态
    onTableSelectable(row) {
      return row.alarmIsHandle == 0;
    },
   //  显示报警详情
    handleShowDetails(row) {
      if (row.isCommercialVehicles) {
        this.detailsData = { ...row }
        this.isVisible = true;
        return false;
      }
      this.isShowDetails = true;
      // let imgList = [];
      // let videoList = [];
      // if (row.attachment && row.attachment.length) {
      //    row.attachment.map(val=>{
      //       if (!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(val)) {
      //          videoList.push(val);
      //       } else {
      //          imgList.push(val);
      //       }
      //    })
      // }
      // row.imgList = row.imgAttachment;
      // row.videoList = videoList;
      this.detailsData = {
         ...row,
         lonLat: `${row.lonBegin},${row.latBegin}`,
         alarmName: row.type,
         speed: row.speedBegin,
         handleState: row.alarmIsHandle,
         vehicleClassName: row.machineryEquipmentType,
      }
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
}
.AlarmTabs {
  width: 100%;
  height: 100%;
}

.table-pane {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  .search-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 33px;
    margin: 0 0 15px 0;
    .select {
      width: 240px;
      // margin: 0 10px 10px 0;
    }
  }
}

.el-pagination {
  text-align: center;
  margin: 5px 0;
}

::v-deep .el-button {
  padding: 9px 5px 8px;
}

.popoverContent {
}
.popoverContentHeader {
  height: 32px;
  display: flex;
  justify-content: space-between;
  & > .title {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>