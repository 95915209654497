<template>
  <div class="AlarmIcon">
    <el-popover width="80%"
      v-model="visible"
      trigger="manual"
      placement="bottom-end"
      popper-class="popCls">
      <div class="content-pane">
        <div class="closeIcon"
          @click.stop="closeClick(false)">
          <ElIcon :size="24"
            name="el-icon-close"></ElIcon>
        </div>

        <AlarmTabs ref="alarmTabsRef" />
      </div>
      <template #reference>
        <span class="pointer"
          @click.stop="changeVisible">
          <el-badge :value="alarmNum">
            <img class="alarmImg"
              src="../../assets/images/报警.gif" />
            <!-- <Iconfont class="alarm-tip" :size="26" name="icon-baojingtixing1"></Iconfont> -->
          </el-badge>
        </span>
      </template>
    </el-popover>
  </div>
</template>

<script>
import { getTodayAlarm } from '@/api/getHome.js';
import AlarmTabs from '@/components/AlarmTabs';
import { hasPerms, dataPermissions } from "@/utils/auth";

export default {
  name: "AlarmIcon",
  components: {
    AlarmTabs,
  },
  data() {
    this.intervalId = null;
    return {
      alarmNum: 0, // 报警总数量
      visible: false, // 弹框状态

    };
  },
  mounted() {
   if (hasPerms('M01')) {
    this.getTodayAlarmCount();
    this.intervalId = setInterval(async () => {
      await this.getTodayAlarmCount();
    }, 1000 * 15);
   }
  },

  beforeDestroy() {
    this.intervalId ? clearInterval(this.intervalId) : '';
  },
  destroyed() {
    this.intervalId ? clearInterval(this.intervalId) : '';
  },
  deactivated() {
    this.intervalId ? clearInterval(this.intervalId) : '';
  },
  methods: {
    changeVisible() {
      this.visible = !this.visible;
      this.$refs.alarmTabsRef.alarmiProp.type ? this.$refs.alarmTabsRef.handleClick({ name: this.$refs.alarmTabsRef.alarmiProp.type }) : this.$refs.alarmTabsRef.search(hasPerms('M09SM04-abnormal') ? 0 : 1);
    },

    closeClick(val) {
      // this.$refs.alarmTabsRef.closeClick();
      this.visible = val;
    },

    // 获取报警总数
    async getTodayAlarmCount() {
      try {
        const result = await getTodayAlarm();
        if (result.flag !== 1) return;
        const { alarmNum } = result.obj;
        this.alarmNum = alarmNum;
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.AlarmIcon {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  margin: 0 0px 0 25px;
}
.content-pane {
  width: 100%;
  height: 800px;
}
.alarmImg {
  width: 25px;
}
.alarm-tip {
  margin: 15px 18px 0 0;
}

.closeIcon {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  z-index: 100;
}

::v-deep .el-badge__content.is-fixed {
  top: 30px;
  right: 12px;
}

.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
.popCls {
  width: 90%;
  top: 56px !important;
  z-index: 1000 !important;
}
</style>