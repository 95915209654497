import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);

/**
 * 获取ACC报表数据
 * @param {JSON} val
 */
export const getAccReport = val => {
    return axios.get("accReport/getAccReport.do", { params: val });
};

/**
 * 获取ACC报表详细数据
 * @param {JSON} val
 */
export const getAccDetail = val => {
    return axios.get("accReport/getAccDetail.do", { params: val });
};
/**
 * 车辆油耗统计
 * @param {JSON} val
 */
export const getGasStatistic = val => {
    return axios2.get("gasRecord/gasStatistic", { params: val });
};
/**
 * 指令下发数据
 * @param {JSON} val
 */
export const getCommandReport = val => {
    return axios.get("commandReport/getCommandReport.do", { params: val });
};


/**
 * 加油信息
 * @param {JSON} val
 */
export const getGasListReport = val => {
    return axios2.get("gasRecord/gasList", { params: val });
};

/**
 * 加油信息删除记录
 * @param {JSON} val
 */
export const deleteGasListReport = val => {
    return axios2.get("gasRecord/deleteList", { params: val });
};

/**
 * 终端升级记录
 * @param {JSON} val
 */
export const TerminalNoPag = val => {
    return axios.get("monitor/pageTerminalNoPag.do", { params: val });
};

/**
 * 上下线统计
 * @param {JSON} val
 */
export const OffLineReport = val => {
    return axios.get("accReport/getOffLineReport.do", { params: val });
};

/**
 * 上下线统计明细
 * @param {JSON} val
 */
export const getOffLineDetail = val => {
    return axios.get("accReport/getOffLineDetail.do", { params: val });
};


/**
 * 查询平台连通率
 * @param {JSON} val
 */
export const getPdeConnectivityRate = val => {
    return axios.get("connectrate/getconnectrate.do", { params: val });
};

/**
 * 平台连通率明细查询
 * @param {JSON} val
 */
export const getPdeConnectivityRateDetail = val => {
    return axios.get("connectrate/getconnectrateDetail.do", { params: val });
};


export const getTachographsReport = val => {
    return axios.get("accReport/pageTachograph.do", { params: val });
};

/**
 * 日里程统计
 * @param {JSON} val
 */
export const doGetDayMileageReport = val => {
    return axios2.get("mileageReport/getDayMileageReport", { params: val });
};

/**
 * 里程统计详情
 * @param {JSON} val
 */
export const doGetMileageDetail = val => {
    return axios2.get("mileageReport/getDayMileageDetail", { params: val });
};

/**
 * 月里程报表
 * @param {JSON} val
 */
export const getMonthMileageReport = (params) => { return axios2.get('mileageReport/getMonthMileageReport', { params }); };

/**
 * 月程统计详情
 * @param {JSON} val
 */
export const getMonthMileageDetail = val => {
    return axios2.get("mileageReport/getMonthMileageDetail", { params: val });
};


/**
 * 获取指定车组、车辆的行驶里程
 * @param {*} params.vehicleId //车辆ID
 * @param {*} params.groupId //车组ID
 * @param {*} params.beginTime //开始时间
 * @param {*} params.endTime //结束时间
 * @param {*} params.pageNumber
 * @param {*} params.pageSize
 */
export const doVehicleMileage = ({
    type,
    vehicleId,
    groupId,
    beginTime,
    endTime,
    pageNumber = 0,
    pageSize = 0
}) => {
    return axios.get("mileageReport/doVehicleMileage.do", {
        params: {
            type,
            vehicleId,
            groupId,
            beginTime,
            endTime,
            pageNumber,
            pageSize,
            flag: ~~!!vehicleId
        }
    });
};

/**
 * 离线报警
 * @param {JSON} val
 */
export const getOffLineReport = val => {
    return axios.get("offLineReport/getOfflineReport.do", { params: val });
};

/**
 * 失联统计
 * @param {JSON} val
 */
export const getMissContactReport = val => {
    return axios.get("/nocontact/findNoContactInfo.do", { params: val });
};

/**
 * 油量统计
 * @param {JSON} val
 */
export const getOilReport = val => {
    return axios.get("oilReport/getOilReport.do", { params: val });
};

/**
 * 油量详情
 * @param {JSON} val
 */
export const getOilReportDetail = val => {
    return axios.get("oilReport/getOilReportDetail.do", { params: val });
};

/**
 * 油量轨迹图标数据
 * @param {JSON} val
 */
export const getVehOilTrackMongo = val => {
    return axios.get("vehicle/getVehOilTrackMongo.do", { params: val });
};

/**
 * 操作记录
 * @param {JSON} val
 */
export const searchOperRecord = val => {
    return axios.get("OperatingRecord/searchOperRecord.do", { params: val });
};

/**
 * 操作记录
 * @param {JSON} val
 */
export const operateRecord = val => {
    return axios.get("videoOperate/operateRecord.do", { params: val });
};

/**
 * 视频操作记录明细
 * @param {*} val
 */
export const operateRecordDetail = val => {
    return axios.get("videoOperate/operateRecordDetail.do", { params: val });
};

/**
 * 停车统计
 * @param {JSON} val
 */
export const getNowStopRePort = val => {
    return axios.get("stopReport/getNowStopRePort.do", { params: val });
};

/**
 * 查询图片数据
 * @param {JSON} val
 */
export const getPhotograph = val => {
    return axios.get("monitor/getPhotograph.do", { params: val });
};

/**
 * 显示图片
 * @param {JSON} val
 */
export const pictureShow = val => {
    return axios.get("monitor/pictureShow.do", {
        responseType: "blob",
        params: val
    });
};

/**
 * 查询标签
 * @param {JSON} val
 */
export const getLabels = val => {
    return axios.get("label/getLabels", { params: val });
};

/**
 * 图片绑定标签
 * @param {JSON} val
 */
export const saveLabels = params => {
    return axios.post('label/imageSignLabel', params, { headers: { 'Content-Type': 'application/json' } });
};

/**
 * 下载图片
 * @param {JSON} val
 */
export const pictureDownload = val => {
    return axios.get("monitor/pictureDownload.do", {
        responseType: "blob",
        params: val
    });
};

/**
 * 查询登退签数据
 * @param {JSON} val
 */
export const getBoardSign = val => {
    return axios.get("boardSignReport/getBoardSign.do", { params: val });
};

/**
 * 总里程数据
 * @param {JSON} val
 */
export const getMileageNow = val => {
    return axios.get("mileageReport/getMileageNow.do", { params: val });
};

/**
 *FTP视频数据
 * @param {JSON} val
 */
export const getDownloadInfo = val => {
    return axios.get("/ftpFileInfo/getDownloadInfo.do", { params: val });
};

/**
 *报警数据
 * @param {JSON} val
 */
export const getAlarmAnalysis = val => {
    return axios2.get("alarmRecord/getAlarmAnalysis.do", {
        params: {...val, alarmTypes: val.alarmTypes.join(",") }
    });
};

/**
 *报警数据
 * @param {JSON} val
 */
export const getAlarmDetailByGroup = val => {
    return axios2.get("alarmRecord/getAlarmDetailByGroup.do", {
        params: {...val, alarmTypes: val.alarmTypes.join(",") }
    });
};

/**
 *报警数据详情
 * @param {JSON} val
 */
export const getAlarmDetail = val => {
    return axios2.get("alarmRecord/getAlarmDetail.do", { params: val });
};

/**
 *报警次数
 * @param {JSON} val
 */
export const getAlarmCount = val => {
    return axios2.get("alarmRecord/getAlarmCount.do", { params: val });
};

/**
 *报警次数
 * @param {JSON} val
 */
export const getAlarmTypeCount = val => {
    return axios2.get("alarmRecord/getAlarmTypeCount.do", { params: val });
};

/**
 *报警处理统计
 * @param {JSON} val
 */
export const getAlarmHandle = val => {
    return axios2.get("alarmRecord/getAlarmHandle.do", { params: val });
};
// export const getAlarmHandle = (val) => {
//   return axios.post('alarm/getAlarmHandle.do', Object.entries(val).map(([key, val]) => `${key}=${val}`).join('&'), { headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" } })
// }

/**
 *报警处理统计
 * @param {JSON} val
 */
export const getAlarmOverSpeed = val => {
    return axios2.get("alarmRecord/getAlarmOverSpeed.do", { params: val });
};

/**
 *报警率
 * @param {JSON} val
 */
export const getAlarmRate = val => {
    return axios2.get("alarmRate/getAlarmRate.do", { params: val });
};

/**
 *报警率
 * @param {JSON} val
 */
export const getAlarmRateDetail = val => {
    return axios2.get("alarmRate/getAlarmRateDetail.do", { params: val });
};

/**
 *ADAS报表
 * @param {JSON} val
 */
export const getAdasInfo = val => {
    return axios2.get("alarmRecord/getAdasInfo.do", { params: val });
};

/**
 *ADAS报表详情
 * @param {JSON} val
 */
export const getAdasDetail = val => {
    return axios2.get("alarmRecord/getAdasDetail.do", { params: val });
};

/**
 *ADAS报表详情
 * @param {JSON} val
 */
export const getAdasInfoCount = val => {
    return axios.get("adasReport/getAdasInfoCount.do", { params: val });
};

/**
 *ADAS报表详情
 * @param {JSON} val
 */
export const findVehicleTemp = val => {
    return axios.get("tempReport/findVehicleTemp.do", { params: val });
};

/**
 *ADAS报表详情
 * @param {JSON} val
 */
export const findVehicleTempDetail = val => {
    return axios.get("tempReport/findVehicleTempDetail.do", { params: val });
};

/**
 *邮箱 短信下发记录查询
 * @param {JSON} val
 */
export const getEmailRecord = val => {
    return axios.get("emailReport/getEmailRecord.do", { params: val });
};

/**
 *速度筛选明细报表
 * @param {JSON} val
 */
export const speedFilterReport = val => {
    return axios.get("speedFilter/speedFilterReport.do", { params: val });
};

/**
 *速度筛选明细报表
 * @param {JSON} val
 */
export const queryDetails = val => {
    return axios.get("speedFilter/queryDetails.do", { params: val });
};
/**
 *轨迹查询报表
 * @param {JSON} val
 */

export const trackFromFS = val => {
    return axios.get("speedFilter/trackFromFS.do", { params: val });
};

/**
 * 轨迹连续性分析报表
 * @param {*} params.vehicleId 车辆id
 * @param {*} params.overtime 终端持续时长(分钟数)必填
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 * @param {*} params.pageNumber 当前页
 * @param {*} params.pageSize 每页大小
 */
export const trackDisruption = params =>
    axios.get("vehicle/trackDisruption.do", { params });

/**
 *速度筛选报表导出
 * @param {JSON} val
 */
export const SfrExport = (url, val) => {
    return axios.get(url, { responseType: "blob", params: val });
};

/**
 * 车辆自动授权查询报表
 */
export const pagevehicleAuthor = val => {
    return axios.get("rechargeUser/pagevehicleAuthor.do", { params: val });
};


/**
 * 获取车组具体评分情况
 * @param {*} params.groupId // 车组
 * @param {*} params.startTime //开始时间
 * @param {*} params.endTime //结束时间
 * @param {*} params.pageSize //每页大小
 * @param {*} params.pageNumber //当前页
 */
export const getDriverBehavior = (params) => axios2.get('activeDefense/getDriverBehavior.do', { params });

/**
 * 获取车辆报警趋势
 * @param {*} params.flag //0: 车组， 1：车辆
 * @param {*} params.groupId //车组ID
 * @param {*} params.vehicleId //车辆ID
 * @param {*} params.startTime //开始时间
 * @param {*} params.endTime //结束时间
 */
export const getAlarmGroup = (params) => axios2.get('activeDefense/getAlarmGroup.do', { params });

/**
 * 查询时间范围内前后5分钟图片
 * @param {*} params.beginTime // 开始时间
 * @param {*} params.endTime // 结束时间
 * @param {*} params.vehicleId // 车辆ID
 */
export const findPhotoByVehid = (params) => axios.get('monitor/findPhotoByVehid.do', { params });

/**
 *车辆停车行驶视频
 * @param {JSON} val
 */
export const getVehicleStopDrivinginfo = val => {
    return axios.get("stopDrivingVideo/getVehicleStopDrivinginfo.do", { params: val });
};

/**
 *驾驶员识别
 * @param {JSON} val
 */
export const getDriverRecord = val => {
    return axios.get("adasReport/getDriverRecord.do", { params: val });
};

/**
 *车辆入网率报表
 * @param {JSON} val
 */
export const getinnetrate = val => {
    return axios.get("innet/getinnetrate.do", { params: val });
};

/**
 *车辆入网率报表明细
 * @param {JSON} val
 */
export const getinnetrateDetail = val => {
    return axios.get("innet/getinnetrateDetail.do", { params: val });
};

/**
 *车辆上线率报表
 * @param {JSON} val
 */
export const getonlinerate = val => {
    return axios.get("online/getonlinerate.do", { params: val });
};

/**
 *车辆上线报表明细
 * @param {JSON} val
 */
export const getOnlinerateDetail = val => {
    return axios.get("online/getOnlinerateDetail.do", { params: val });
};


/**
 *漂移率查询
 * @param {JSON} val
 */
export const getDatarate = val => {
    return axios.get("drift/getdatarate.do", { params: val });
};

/**
 *漂移率查询明细
 * @param {JSON} val
 */
export const getDriftrateDetail = val => {
    return axios.get("drift/getdriftrateDetail.do", { params: val });
};

/**
 *轨迹完整率
 * @param {JSON} val
 */
export const getTrackcomplete = val => {
    return axios.get("track/gettrackcomplete.do", { params: val });
};

/**
 *数据合格率
 * @param {JSON} val
 */
export const getDataPassRate = val => {
    return axios.get("correctdata/getdatarate.do", { params: val });
};


/**
 *查岗响应率
 * @param {JSON} val
 */
export const getCheckPostrate = val => {
    return axios.get("checkpost/getcheckpostrate.do", { params: val });
};

/**
 *查岗响应率明细
 * @param {JSON} val
 */
export const getCheckPostrateDetail = val => {
    return axios.get("checkpost/getcheckpostrateDetail.do", { params: val });
};

/**
 *超速次数报表
 * @param {JSON} val
 */
export const getOverspeed = val => {
    return axios.get("vehicleoverspeed/getoverspeed.do", { params: val });
};

/**
 *超速次数明细
 * @param {JSON} val
 */
export const getOverspeedDetail = val => {
    return axios.get("vehicleoverspeed/getoverspeedDetail.do", { params: val });
};


/**
 *疲劳驾驶时长报表
 * @param {JSON} val
 */
export const getVehicletired = val => {
    return axios.get("vehicletired/getvehicletired.do", { params: val });
};

/**
 *疲劳驾驶时长明细
 * @param {JSON} val
 */
export const getVehicletiredDetail = val => {
    return axios.get("vehicletired/getvehicletiredDetail.do", { params: val });
};

/**
 *分段限速报表
 * @param {JSON} val
 */
export const getAlarmSectionSpeedLimit = val => {
    return axios2.get("alarmRecord/getAlarmSectionSpeedLimit", { params: val });
};

/**
 *漏报检测查询
 * @param {JSON} val
 */
export const getMissAlarmReport = val => {
    return axios2.get("missalarm/getMissAlarmReport.do", { params: val });
};

/**
 *漏报明细
 * @param {JSON} val
 */
export const getMissAlarmDetail = val => {
    return axios2.get("missalarm/getMissAlarmDetail.do", { params: val });
};

//车辆在线时长统计查询
export const getVehicleOnlineTime = (val) => { return axios2.get('vehicleonlinetime/getVehicleOnlineTime.do', { params: val }); };

//车辆管理报表查询
export const getVehicleManagerReportInfo = (val) => { return axios.get('vehiclemanagerreport/getVehicleManagerReportInfo.do', { params: val }); };

/**
 * 获取终端故障记录信息
 * @param {*} params
 */
export const getTerminalFaultInfo = (params) => axios.get('terminalfault/getTerminalFaultInfo.do', { params });

/**
 * 处理终端故障
 * @param {*} params
 */
export const handleTerminalFault = (params) => axios.get('terminalfault/handleTerminalFault.do', { params });

/**
 * 途经点统计
 * @param {*} params
 */
export const getPassPointInfo = (params) => axios.get('passpoint/getPassPointInfo.do', { params });

/**
 * 视频操作记录
 * @param {*} params
 */
export const getVideoOperate = (params) => axios.get('videoOperate/operateRecord.do', { params });

/**
 * 转弯超速报警明细
 * @param {*} params
 */
export const getAdasTrunDetail = (params) => axios.get('driverbehavioralarm/getAdasDetail.do', { params });

/**
 * 转弯超速报警
 * @param {*} params
 */
export const findDriverbehavioralarm = (params) => axios.get('driverbehavioralarm/findDriverbehavioralarm.do', { params });


/**
 * 获取附件信息
 * @param {*} params
 */
export const getAlarmFileById = (params) => axios2.get('alarmRecord/getAlarmFileById', { params });