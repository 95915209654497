<!--
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-06-27 17:35:01
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-06-29 17:23:00
 * @FilePath: \platform-pc-front\src\layout\Header\AvatarWithText.vue
 * @Description: 头像加文字的封装组件
-->
<template>
  <div :class="{ 'avatar-header': true, 'up-down-text': upDownText }">
    <el-avatar
      @click="clickAvatar"
      :src="localUrl"
      :size="35"
      @error="errorHandler"
      class="av_bg"
    >
      <!--没有图片显示用户名第一个字符 -->
      <span>{{ text[0] }}</span>
    </el-avatar>
    <span :class="textDerection">{{ resultText }}</span>
  </div>
</template>

<script>
export default {
  name: "AvatarWithText",
  props: {
    //头像地址
    url: {
      type: [Object, String],
      default: "",
    },
    //头像文字
    text: {
      type: String,
      default: "user",
    },
    //文字方位，上下左右
    derection: {
      type: String,
      default: "right",
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ["left", "right", "top", "bottom"].indexOf(value) !== -1;
      },
    },
    //最大显示字符长度
    maxtext: {
      type: Number,
      default: 3,
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return value > 0;
      },
    },
  },
  mounted() {
    //通过控制样式来控制文字方位
    if (this.derection === "left") {
      this.textDerection.order = true;
    } else if (this.derection === "bottom") {
      this.upDownText = true;
    } else if (this.derection === "top") {
      this.upDownText = true;
      this.textDerection.order = true;
      this.textDerection.fix_top = true;
    }
  },
  data() {
    return {
      textDerection: { text: true, order: false, fix_top: false },
      upDownText: false,
      leftRightText: false,
    };
  },
  methods: {
    clickAvatar(e) {
      this.$emit("clickAvatar", e);
    },
    errorHandler() {
      return false;
    },
  },
  computed: {
    resultText() {
      //计算是否超过最长要求字符
      return this.text.length > this.maxtext
        ? `${this.text.substring(0, this.maxtext)}...`
        : this.text;
    },
    localUrl() {
      if (typeof(this.url)  == "string") {
        return this.url;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.avatar-header {
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: space-between;
}

.up-down-text {
  flex-direction: column;
}

.text {
  margin: 10px;
  font-size: 15px;
  font-weight: 300;
  color: #efefef;
}

.order {
  order: -1;
}

.fix_top {
  margin: 0px 10px;
}
.av_bg {
  background-color: #397394;
}
</style>
