<template>
  <div class="HeaderFullscreen">
    <Iconfont
      class="alarm-tip"
      :size="23"
      :name="iconName"
      @click="handleClick"
    ></Iconfont>
  </div>
</template>

<script>

/**
 * 全屏图标
 */

import { isElement } from 'lodash';

export default {
  name: 'FullScreenIcon',
  emits: [
    /**
     * 全屏状态变化是触发
     */
    'change'
  ],
  props: {

    /**
     * 是否全屏状态
     */
    value: {
      type: Boolean,
      default: false,
    },

    /**
     * 全屏元素
     */
    el: {
      type: [Object, String],
      default: null,
    },

    /**
     * 全屏图标
     */
    activeName: {
      type: String,
      default: 'icon-quanping1'
    },
    /**
     * 退出全屏图标
     */
    closeName: {
      type: String,
      default: 'icon-tuichuquanping'
    }
  },
  data() {
    return {
      // 是否全屏
      isFullscreen: false,
    };
  },
  computed: {
    iconName() {
      const { isFullscreen, activeName, closeName } = this;
      return isFullscreen ? closeName : activeName;
    }
  },
  watch: {
    value(val) {
      this.isFullscreen = val;
    }
  },
  beforeMount() {
    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    document.addEventListener('fullscreenchange', this.onFullscreenChange);
    this.isFullscreen = this.value;
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.onFullscreenChange);
  },
  methods: {

    getFullScreenEl() {
      const { el } = this;
      return (isElement(el)
        ? el
        : document.querySelector(el)) || document.documentElement;
    },
    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },

    handleClick() {
      const isFullscreen = !this.isFullscreen;

      if (isFullscreen) { // 进入全屏
        this.requestFullscreen();
      } else {
        // 退出全屏
        this.exitFullscreen();
      }

    },
    onFullscreenChange() {
      const fullscreenEl = this.getFullScreenEl();

      const isFullscreen = fullscreenEl === document.fullscreenElement;

      this.isFullscreen = isFullscreen;
      this.$emit('input', isFullscreen);
      this.$emit('change', isFullscreen, document.fullscreenElement);
    }
  }


}
</script>

<style lang="scss" scoped>
.HeaderFullscreen {
  width: 26px;
  margin: 18px 0;
  cursor: pointer;
}
</style>