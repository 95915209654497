<template>
  <el-container class="appLayout" direction="vertical">
    <!-- 页面头部布局 -->
    <Header class="appHeader"></Header>
    <!-- 页面内容布局 -->
    <Content class="appContent"></Content>
    
  </el-container>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/layout/Header';
import Content from '@/layout/Content';
// import { getWorkloadAlarm } from "@/api/getData"
// import VersionWindow from '@/components/VersionWindow'
// import { getAddresses } from '@/components/AMap/utils';
// import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';

export default {
  name: 'AppLayout',
  components: {
    Header,
    Content,
  },
  data(){
    return {
      isPolling: true,
      dialogVisible: false,
      alarmTextarea: '',
      alarmRadio: '1',
      windowList: [],
      winList: [],
      currentWindow: {}
    }
  },
  computed: {
    ...mapState(['userInfo', 'appToken']),
  },
  created() {
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
.appLayout {
  position: relative;
  height: 100%;
  }

.appHeader {
  position: relative;
  height: 63px !important;
}
.appContent {
  position: relative;
  height: calc(100% - 63px);
}
</style>