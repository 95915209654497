/**
 * `MessageBox` 弹框提示
 */

import { MessageBox } from 'element-ui';


/**
 * MessageBox弹框提示
 * @param {*} options 
 * @returns 
 */
function confirm(options = {}) {
  let { title, message, ...ops } = options;
  return MessageBox.confirm(message,
    title,
    {
      closeOnClickModal: false,
      closeOnPressEscape: false,
      type: 'info',
      ...ops,
    }).then(() => true)
    .catch(() => false);
};

/**
 * 提示弹框
 */
function info(options = {}) {
  return confirm({
    closeOnClickModal: false,
    closeOnPressEscape: false,
    ...options,
    type: 'info',
  });
};

/**
 * 成功弹框
 */
function success(options = {}) {
  return confirm({
    closeOnClickModal: false,
    closeOnPressEscape: false,
    ...options,
    type: 'success ',
  });
};

/**
 * 警告
 */
function warning(options = {}) {
  return confirm({
    closeOnClickModal: false,
    closeOnPressEscape: false,
    ...options,
    type: 'warning',
  });
};

/**
 * 错误
 */
function error(options = {}) {
  return confirm({
    closeOnClickModal: false,
    closeOnPressEscape: false,
    ...options,
    type: 'error',
  });
};

/**
 * 退出弹框提示
 */
function exit() {
  return warning({
    title: '退出提示',
    message: '确认退出吗?'
  });
};

/**
 * 删除提示
 */
function remove(message = '确认删除吗?', title = '删除提示', options = {}) {
  return warning({
    title,
    message,
    ...options,
  });
}

export const MsgBox = {
  confirm,
  info,
  success,
  warning,
  error,
  exit,
  remove,
};