<!--
 * @Author: zhuchaowei
 * @Date: 2024-03-27 17:27:38
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-20 15:02:03
 * @Description: 
-->
<template>
  <HeaderItem class="headerLogo">
    <div class="imgCls">
      <img :src="logoUrl" />
      <div class="logoTitle">{{ logoTitle }}</div>
    </div>
  </HeaderItem>
</template>

<script>
/**
 * 头部LOGO组件
 */

import { mapState } from "vuex";
import HeaderItem from "@/layout/HeaderItem";

export default {
  name: "Logo",
  components: {
    HeaderItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["headerLogoUrl", "userInfo"]),
    imgStyle() {
      return {
        backgroundImage: `url(${this.headerLogoUrl})`,
      };
    },
    logoUrl() {
      let url = "";
      this.$store.state.userRouter?.children?.find((r) => r.code === "zgsy") &&
      this.userInfo.name != "广东一立科技"
        ? ''
        : (url = require("../../assets/images/logo.png"));
        // (url = require("../../assets/images/petrochinalogo.png"))  中石油暂停处理
      return url;
    },
    logoTitle() {
      let text = "一立云";
      this.$store.state.userRouter?.children?.find((r) => r.code === "zgsy") &&
      this.userInfo.name != "广东一立科技"
        ? (text = "")
        // 中国石油
        : (text = "一立云");
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.headerLogo {
  position: relative;
  height: 100%;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 7px 0px;
  margin: 0 0 0 15px;
}
.imgCls {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  //align-items: center;
  > img {
    height: 100%;
  }
  > span {
    color: #fff;
    font-size: 18px;
    // font-weight: bold;
    letter-spacing: 2px;
    margin-left: 2px;
    line-height: 90px;
  }
  .logoTitle {
    color: #fff;
    font-size: 18px;
    // font-weight: bold;
    letter-spacing: 2px;
    margin-left: 2px;
    line-height: 90px;
  }
}
</style>
