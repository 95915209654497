<template>
  <Dialog :value="dialogVisible"
    width="660px"
    height="280px"
    okText="确定授权"
    :submit="submitOk"
    hiddenCancel
    @close="$emit('input',$event)">
    <!-- 标题 -->
    <template #title>
      <span>车辆授权</span>
    </template>

    <!-- 内容区   则为自定义授权-->
    <div class="mainCls">
      <el-row class="align-start">
        <el-col :span="8">
          <span>授权车辆:</span>
          <el-input disabled
            type="textarea"
            :rows="10"
            :value="vehicleList.map(obj=>obj.plate).join(',')"
            class="rightInput"></el-input>
        </el-col>
        <el-col :span="16">
          <el-row v-if="gf1078List.length">
            <el-col :span="24">
              <span class="mark">GF-1078</span>
              设备共有
              <span class="mark">{{gf1078List.length}}</span>
              台, 将消耗
              <span class="mark">{{gf1078Coin}}</span>
              个授权币
            </el-col>
          </el-row>
          <el-row v-if="gb1078List.length">
            <el-col :span="24">
              <span class="mark">GB-1078</span>
              设备共有
              <span class="mark">{{gb1078List.length}}</span>
              台, 将消耗
              <span class="mark">{{gb1078Coin}}</span>
              个授权币
            </el-col>
          </el-row>
          <el-row v-if="gaList.length">
            <el-col :span="24">
              <span class="mark">GA系列</span>
              设备共有
              <span class="mark">{{gaList.length}}</span>
              台, 将消耗
              <span class="mark">{{gaCoin}}</span>
              个授权币
            </el-col>
          </el-row>
          <el-row v-if="gprsList.length">
            <el-col :span="24">
              <span class="mark">部标系列</span>
              设备共有
              <span class="mark">{{gprsList.length}}</span>
              台, 将消耗
              <span class="mark">{{gprsCoin}}</span>
              个授权币
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">选择授权时长:</el-col>
            <el-col :span="18">
              <el-radio-group v-model="authTime">
                <el-radio-button v-for="item in authTimeOptions"
                  :key="item.month"
                  :label="item.month">{{item.label}}</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              设备总数共有
              <span class="mark">{{vehicleList.length}}</span>
              台, 将消耗
              <span class="mark color-red">{{totalCoin}}</span>
              个授权币
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import Dialog from '@/components/Dialog';

import {
  batchRecharge,
  vehicleRecharge
} from '@/api/getManagementRenew.js';

/**
 * 授权规则
 * /docs/车辆授权币规则.md
 */

/**
 * 授权时间选项
 */
const authTimeOptions = [
  // 3个月
  { month: 3, label: '3月', gprsUnit: 3, gf1078Unit: 10, gb1078Unit: 10, gaUnit: 10 },
  // 6个月
  { month: 6, label: '6月', gprsUnit: 6, gf1078Unit: 20, gb1078Unit: 20, gaUnit: 20 },
  // 1年
  { month: 12, label: '1年', gprsUnit: 10, gf1078Unit: 25, gb1078Unit: 25, gaUnit: 25 },
  // 3年
  { month: 36, label: '3年', gprsUnit: 30, gf1078Unit: 75, gb1078Unit: 75, gaUnit: 75 },
  // 5年
  { month: 60, label: '5年', gprsUnit: 50, gf1078Unit: 125, gb1078Unit: 125, gaUnit: 125 },
];


export default {
  name: 'AuthCoin',
  components: {
    Dialog,
  },
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    /**
     * 车辆授权列表
     * Array<{
     * vehicleId: 车辆Id
     * plate: 设备名称
     * terminalNo: 终端号
     * terminalType: 终端类型
     * groupId: 车组Id
     * }>
     */
    vehicleList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      authTimeOptions,
      // 授权时长
      authTime: 3
    };
  },
  computed: {
    // 收权限车辆 参数 
    gvids() {
      const { vehicleList } = this;
      // 车组id+逗号+车辆id+分号+车组id+逗号+车辆id[+...]
      return vehicleList.map(obj => `${ obj.groupId },${ obj.vehicleId }`).join(';');
    },

    // 部标设备
    gprsList() {
      const { vehicleList } = this;
      return vehicleList.filter(obj => ['GPRS-部标'].includes(obj.terminalType));
    },
    // 视频设备(GA,GF-1078,GA-1078)
    videoList() {
      const { gaList, gf1078List, gb1078List } = this;
      return [
        ...gf1078List,
        ...gb1078List,
        ...gaList,
      ];
    },
    // GA系类
    gaList() {
      const { vehicleList } = this;
      return vehicleList.filter(obj => ['GA系列'].includes(obj.terminalType));
    },
    // GF-1078系列
    gf1078List() {
      const { vehicleList } = this;
      return vehicleList.filter(obj => ['GF-1078'].includes(obj.terminalType));
    },
    // GB-1078系列
    gb1078List() {
      const { vehicleList } = this;
      return vehicleList.filter(obj => ['GB-1078'].includes(obj.terminalType));
    },

    // 当前选中的授权时间
    currentAuthTime() {
      const { authTime } = this;
      return authTimeOptions.find(obj => obj.month === authTime);
    },
    // 部标消耗币总数
    gprsCoin() {
      const { gprsList, currentAuthTime } = this;
      return gprsList.length * currentAuthTime.gprsUnit;
    },
    // GF-1078消耗币总数
    gf1078Coin() {
      const { gf1078List, currentAuthTime } = this;
      return gf1078List.length * currentAuthTime.gf1078Unit;
    },
    // GB-1078消耗币总数
    gb1078Coin() {
      const { gb1078List, currentAuthTime } = this;
      return gb1078List.length * currentAuthTime.gb1078Unit;
    },
    // GA系列消耗总数
    gaCoin() {
      const { gaList, currentAuthTime } = this;
      return gaList.length * currentAuthTime.gaUnit;
    },
    // 总共消耗币总数
    totalCoin() {
      const { gprsCoin, gf1078Coin, gb1078Coin, gaCoin } = this;
      return gprsCoin + gf1078Coin + gb1078Coin + gaCoin;
    }
  },

  methods: {

    // 授权后提示
    authMsgBox(obj) {
      const { gfDev, gfMoney, gprsDve, gprsMoney } = obj;
      return this.$msgbox({
        title: '授权提示',
        closeOnClickModal: false,
        closeOnPressEscape: false,
        message: (<div class="flex-column">
          {
            gprsDve > 0 ? (
              <div>
                <span>成功充值“GPRS-部标”车辆{gprsDve}台</span>
                <span>, 花费授权币{gprsMoney}个</span>
              </div>
            ) : (<span></span>)
          }
          {
            gfDev > 0 ? (
              <div>
                <span>成功充值“视频设备”车辆{gfDev}台</span>
                <span>, 花费授权币{gfMoney}个</span>
              </div>
            ) : (<span></span>)
          }
        </div>),
      }).catch(() => null);
    },

    async submitOk() {
      const { currentAuthTime, totalCoin, gvids } = this;

      const result = await vehicleRecharge({
        addMonth: currentAuthTime.month,
        gvids,
      });

      if (result.flag !== 1) {
        return this.$message.warning(result.msg);
      }

      await this.authMsgBox(result.obj);

      this.close();
    },
    close() {
      this.$emit('input', false);
    }
  }
}
</script>

<style lang="scss" scoped>
.mainCls {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
}
.el-row {
  margin-bottom: 15px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.mark {
  font-size: 18px;
  margin: 0 3px;
}
.color-red {
  color: red;
  font-size: 24px;
}
.align-start {
  align-items: flex-start;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.rightInput {
  height: 240px;
  overflow-y: auto;
}
</style>