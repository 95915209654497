import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
const axios = createHttp(appConfig.base2GUrl)

/**
 * 查询过期车辆
 * @param {JSON} val 
 */
export const queryVehicleExpired = (val) => { return axios.get('rechargeUser/queryVehicleExpired.do', { params: val }) }

/**
 * 批量充值返回设备分类类型
 * @param {JSON} val 
 */
export const differDevType = (val) => { return axios.get('rechargeUser/differDevType.do', { params: val }) }

/**
 * 查询用户充值信息
 * @param {JSON} val 
 */
export const queryMyState = (val) => { return axios.get('rechargeUser/queryMyState.do', { params: val }) }


/**
 * 批量加车续费
 * @param {JSON} val 
 */
export const lastSubmit = (val) => { return axios.get('uploadExcel/lastSubmit.do', { params: val }) }


/**
 * 自定义车辆充值
 * @param {JSON} val 
 */
export const batchRecharge = (val) => { return axios.get('rechargeUser/batchRecharge.do', { params: val }) }


/**
 * 车辆充值
 * @param {JSON} val 
 */
export const vehicleRecharge = (val) => { return axios.get('rechargeUser/vehicleRecharge.do', { params: val }) }

/**
 * 查询授权记录
 * @param {JSON} val 
 */
export const queryVehicleFreeLog = (val) => { return axios.get('rechargeUser/queryVehicleFreeLog.do', { params: val }) }


/**
 * 授权币划拨
 * @param {JSON} val 
 */
export const rechargeNum = (val) => { return axios.get('rechargeUser/rechargeNum.do', { params: val }) }

/**
 * 查询用户等级
 * @param {JSON} val 
 */
export const searchUserLevel = (val) => { return axios.get('rechargeUser/searchUser1Level.do', { params: val }) }


/**
 * 查询用户划拨记录
 * @param {JSON} val 
 */
export const queryRechargeLog = (val) => { return axios.get('rechargeUser/queryRechargeLog.do', { params: val }) }












