<template>
  <div class="adas-pic-wrap">
    <div class="adas-img"
      :style="{'background-image':`url(${pictureSrc})`}"
      @dblclick="$emit('dblclick')">
      <div v-if="extra && extra.result"
        class="img-info">
        <label>对比结果：{{['匹配成功','匹配失败','超时','没有启用该功能','连接异常','无指定人脸图片','无人脸库'][extra.result]}}</label>
        <label>相似度：{{extra.threshold}}%</label>
        <label>对比类型：{{['插卡比对','巡检比对','点火比对','离开返回比对'][extra.comptrastType]}}</label>
      </div>
      <label class="img-label">抓拍图片</label>
    </div>
    <div v-if="photoSrc"
      class="adas-img"
      :style="{'background-image':`url(${photoSrc})`}"
      @dblclick="$emit('dblclick')">
      <label class="img-label">驾驶员</label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdasPicture',
  props: {
    pictureSrc: { //抓拍图片地址
      type: String,
      default: ''
    },
    photoSrc: { //驾驶员图片地址
      type: String,
      default: ''
    },
    extra: {
      type: Object,
      required: false,
    }
  },
  methods: {
    onDblclick() {
      console.log(1111111)
    }
  }
}
</script>
<style lang='scss'>
.adas-pic-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  .adas-img {
    height: 100%;
    min-width: 50%;
    max-width: 100%;
    background-color: #2b2525;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    font-size: 14px;
    color: #f3e6e6;
    &:first-child {
      flex-grow: 1;
    }
    .img-label {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .img-info {
      position: absolute;
      right: 5px;
      bottom: 20px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>


