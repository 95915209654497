<template>
  <ResizeObserver @resize="handleWrapperResize">
    <div class="HeaderNav">
      <!-- 左移 -->
      <ShiftButton v-if="showShiftButton" class="shiftLeft" @click="handleLeft"></ShiftButton>

      <!-- 导航项 -->
      <div ref="scrollWrap" class="scrollerWrapper">
        <HeaderNavItem
          v-for="(menu, i) in visibleMenus"
          :key="menu.name"
          :menu="menu"
          :width="itemWidth"
          :isActive="activeMenu && (activeMenu.name === menu.name)"
          @click="$emit('click', $event)"
          @close="onClose($event,i)"
        ></HeaderNavItem>
      </div>
      

      <!-- 右移 -->
      <ShiftButton
        v-if="showShiftButton"
        right
        class="shiftRight"
        @click="handleRight"
      ></ShiftButton>
    </div>
  </ResizeObserver>
</template>

<script>
import { throttle } from 'lodash';
import HeaderNavItem from './HeaderNavItem.vue';
import ShiftButton from './ShiftButton.vue';
import ResizeObserver from '@/components/ResizeObserver';
export default {
  name: 'HeaderNav',
  components: {
    ResizeObserver,
    HeaderNavItem,
    ShiftButton,
  },
  props: {
    navData: {
      /** RouteMenu[] */
      type: Array,
      default() {
        return [];
      }
    },
    activeMenu: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      itemWidth: 135,
      showNum: 6,
      startIndex: 0,
      // 左右移动像素
      moveX: 0,
      // 显示左右移动button
      showShiftButton: false,
    };
  },
  computed: {

    visibleMenus() {
      const { navData, showNum } = this;

      if (navData.length <= showNum) return navData;

      let { startIndex } = this;

      if (startIndex <= 0) startIndex = 0;
      let endIndex = startIndex + showNum;
      if (endIndex >= navData.length) endIndex = navData.length;

      if (endIndex - startIndex < showNum) {
        startIndex = endIndex - showNum;
      }
      this.startIndex = startIndex;

      return navData.slice(startIndex, endIndex);
    },
    contentWidth() {
      const { itemWidth, showNum } = this;
      return itemWidth * showNum;
    },
    contentStyle() {
      const { contentWidth } = this;
      return {
        width: contentWidth + 'px',
        // transform: `translateX(${this.moveX}px)`,
      };
    },
  },
  watch: {
    async navData() {
      await this.$nextTick();
      this.handleWrapperResize();
    },

    activeMenu() {
      this.activeMenuChange();
    },
    showNum() {
      this.activeMenuChange();
    }
  },
  mounted() {
    this.handleWrapperResize();
    this.activeMenuChange();
  },
  beforeDestroy() {
  },
  methods: {
    handleWrapperResize() {
      const { itemWidth, navData } = this;
      const { scrollWrap } = this.$refs;
      if (scrollWrap) {
        const { width } = scrollWrap.getBoundingClientRect();
        this.showNum = Math.floor(width / itemWidth);
        this.showShiftButton = (navData.length * itemWidth > width);
        if (!this.showShiftButton) {
          this.startIndex = 0;
        }
        
      }
    },
    onClose(menu) {
      this.$emit('close', menu);
    },
    handleLeft() {
      let { startIndex } = this;
      startIndex--;
      if (startIndex <= 0) startIndex = 0;
      this.startIndex = startIndex;
    },
    handleRight() {
      const { showNum, navData } = this;
      let { startIndex } = this;
      startIndex++;
      if (startIndex + showNum >= navData.length) {
        startIndex = navData.length - showNum;
      }
      this.startIndex = startIndex;
    },
    activeMenuChange() {
      const { visibleMenus, navData, showNum, activeMenu } = this;

      if (visibleMenus.some(obj => obj.name === activeMenu.name)) return;

      const menuIndex = navData.findIndex(obj => obj.name === activeMenu.name);

      let startIndex = menuIndex - showNum + 1;
      if (startIndex <= 0) startIndex = 0;

      this.startIndex = startIndex;
    }
  }

}
</script>

<style lang="scss" scoped>
.HeaderNav {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
}
.scrollerWrapper {
  height: 100%;
  flex: 1;
  z-index: 100;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.scrollContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.scrollMargin {
  margin: 0 35px;
}
.HeaderNavContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.shiftLeft {
  // position: absolute;
  // left: 0;
}
.shiftRight {
  // position: absolute;
  // right: 0;
}
</style>