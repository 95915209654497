<template>
  <div
    ref="navItem"
    :style="{width: width+'px'}"
    :class="['HeaderNavItem', isActive && 'isActive']"
    @click.stop="$emit('click', menu)"
  >
    <!-- 导航项图标, 图片或图标 -->
    <img v-if="isImg" class="HeaderNavIcon" :src="iconName" />
    <Iconfont v-else :name="iconName" :size="28" :mRight="7"></Iconfont>
    <!-- 显示文本 -->
    {{menu.meta.label}}
    <!-- 关闭按钮, 固定项不会显示 -->
    <img
      v-if="!menu.meta.affix"
      class="HeaderCloseIcon"
      :src="closeIcon"
      @click.stop="$emit('close', menu)"
    />
  </div>
</template>

<script>
/**
 * 头部导航项
 */

export default {
  name: 'HeaderNavItem',
  props: {
    /**
     * 是否是激活状态
     */
    isActive: {
      type: Boolean,
      default: false,
    },
    /**
     * 路由信息
     */
    menu: {
      /** RouteMenu */
      type: Object,
      default: null,
    },
    /**
     * 宽度
     */
    width: {
      type: Number,
      default: 110,
    }
  },
  computed: {
    /**
     * 关闭按钮图标
     */
    closeIcon() {
      return require('@/assets/images/header/关闭.png');
    },
    /**
     * 判断是否是图片
     */
    isImg() {
      const { menu } = this;
      if (Array.isArray(menu.meta.icon)) {
        // 如果icon是数组, 则是图片
        return menu.meta.icon.length > 1;
      }
      return false;
    },
    /**
     * 解析图标
     */
    iconName() {
      const { isImg, menu } = this;

      // 图片, 取第二个
      if (isImg) return menu.meta.icon[1];
      // 数组默认第一个, 图标
      if (Array.isArray(menu.meta.icon)) return menu.meta.icon[0];
      return menu.meta.icon;
    }
  },
  watch: {
    isActive() {
      this.scrollIntoView();
    }
  },
  mounted() {
    this.scrollIntoView();
  },
  methods: {
    // 滚动到可视区
    async scrollIntoView() {
      const { isActive } = this;
      const { navItem } = this.$refs;

      if (navItem && isActive) {
        // await this.$nextTick();
        // navItem.scrollIntoViewIfNeeded();
        // navItem.scrollIntoView();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.HeaderNavItem {
  position: relative;
  display: flex;
  padding: 0 10px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  color: #efefef;
  &:hover {
    background-color: #334053;
    .HeaderCloseIcon {
      display: block;
    }
  }
}
.HeaderNavIcon {
  margin-right: 10px;
  width: 35px;
}
.isActive {
  background-color: #334053;
}
.HeaderCloseIcon {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  height: 16px;
  width: 22px;
  background-size: 100% 100%;
  cursor: pointer;
}
</style>