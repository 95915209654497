<template>
  <div>
    <el-dialog width="565px"
      :visible="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      destroy-on-close
      append-to-body>
      <!-- 标题 -->
      <template #title>
        <div class="header">
          <div class="alarm">
            <img :src="alarmImg" />
            <span>紧急情况</span>
          </div>
          <!-- 关闭 -->
          <!-- <span class="exit-icon" title="关闭" @click="onClose">
            <ElIcon name="el-icon-close"></ElIcon>
          </span>-->
        </div>
      </template>

      <div class="MainPane">
        <el-carousel ref="Carousel"
          trigger="click"
          :autoplay="false"
          arrow="never"
          height="200px">
          <el-carousel-item v-for="(item,index) in dataList"
            :key="index"
            :name="`${index.toString()}`">
            <div class="itemPane"
              v-for="alarm in alarms"
              :key="alarm.key">
              <label class="pointRed">
                <span></span>
              </label>
              <label class="itemTitle">{{alarm.label}}</label>
              <label class="itemText">{{ item[alarm.key] }}</label>
            </div>
          </el-carousel-item>
        </el-carousel>

        <el-pagination :total="tablePage.total"
          :current-page="tablePage.pageNumber"
          @current-change="changePage"
          :page-size="tablePage.pageSize"></el-pagination>
      </div>

      <span slot="footer"
        class="bottom">
        <el-button type="danger"
          size="mini"
          @click="onHandleClick">立即处理</el-button>
      </span>
    </el-dialog>
    <el-dialog width="700px"
      :visible="visibleCapsize"
      :close-on-press-escape="false"
      :show-close="false"
      :before-close="handleCapsizeClose"
      destroy-on-close
      append-to-body>
      <!-- 标题 -->
      <template #title>
        <div class="header">
          <div class="alarm">
            <img :src="alarmImg" />
            <span>{{capsizeAlarmTitle}}</span>
          </div>
          <div v-if="isConcealed"
            @click="handleWhetherDisplayed">
            30分钟内不再显示
          </div>
          <!-- 关闭 -->
          <!-- <span class="exit-icon" title="关闭" @click="onClose">
            <ElIcon name="el-icon-close"></ElIcon>
          </span>-->
        </div>
      </template>

      <div class="MainPane">
        <el-carousel ref="Carousel"
          trigger="click"
          :autoplay="false"
          arrow="never"
          height="240px">
          <el-carousel-item v-for="(item,index) in dataCapsizeList"
            :key="index"
            :name="`${index.toString()}`">
            <div class="itemPane itemPane2"
              v-for="alarm in alarmsCapsize"
              :key="alarm.key">
              <label class="pointRed">
                <span></span>
              </label>
              <label class="itemTitle">{{alarm.label}}</label>
              <label class="itemText"
                style="display:flex;">
                <div :style="{width: alarm.label=='告警类型:' && (item.alarmDefineAlarmType == 'powerOffCapsizeAlarm' || item.alarmDefineAlarmType == 'powerOnCapsizeAlarm') ? '180px' : '100%'}">
                  {{ item[alarm.key] }}
                </div>
                <div class="itemPane itemInPane"
                  v-if="alarm.label=='告警类型:' && (item.alarmDefineAlarmType == 'powerOffCapsizeAlarm' || item.alarmDefineAlarmType == 'powerOnCapsizeAlarm')">
                  <label class=" pointRed"
                    style="margin: 0">
                    <span></span>
                  </label>
                  <label class="itemTitle">状态</label>
                  <label class="itemText">{{ item.isAcc ? '关机' : '开机' }}</label>
                </div>
              </label>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <span slot="footer"
        class="bottom capsize-bottom">
        <el-pagination :total="tableCapsizePage.total"
          :current-page="tableCapsizePage.pageNumber"
          :pager-count="5"
          @current-change="changeCapsizePage"
          :page-size="tableCapsizePage.pageSize"></el-pagination>
        <div>当前第<span style="color:#1989fa">{{ capsizeRemindCount }}</span>次弹窗</div>
        <div>
          <el-button type="danger"
            size="mini"
            @click="onCapsizeKnowClick">
            <div style="display: flex;align-items: center;">
              <img :src="knowImg"
                style="width: 20px;height: 20px;" />
              <span>我已知晓</span>
            </div>
          </el-button>
          <el-button type="danger"
            size="mini"
            style="margin-right: 6px"
            @click="onGoPathClick">
            <div style="display: flex;align-items: center;">
              <div style="height: 20px"></div>
              <span>立即查看</span>
            </div>
          </el-button>
        </div>
      </span>
    </el-dialog>

    <Dialog @on-cmdSend="onCmdSendChange"
      :visible="handleVisible"
      :iProp="alarmiProp"
      @input="closeDialog" />
  </div>
</template>

<script>
import { getVehicleOneKey, getCapsizeAlarm, alarmWhetherHandle, alarmWhetherDisplayed } from '@/api/getHome.js';
import Dialog from '@/components/AlarmHandelDialog';
import { getAddresses } from '@/components/AMap/utils';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';
import { mapMutations } from 'vuex';
import { hasPerms, dataPermissions } from "@/utils/auth";

export default {
  name: "UrgentAlarm",
  components: {
    Dialog,
  },
  data() {
    this.intervalId = null;
    return {
      visible: false, // 紧急报警模态框状态
      visibleCapsize: false,
      // visible: true, // 紧急报警模态框状态
      handleVisible: false, // 报警处理弹窗
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      dataList: [], // 紧急报警数据
      tablePage: { //分页
        pageNumber: 1,
        pageSize: 1,
        total: 0,
      },
      dataCapsizeList: [], // 紧急报警数据
      tableCapsizePage: { //分页
        pageNumber: 1,
        pageSize: 1,
        total: 0,
      },
      alarms: [
        { label: '车 组:', value: '--', key: 'groupName' },
        { label: '设备名称:', value: '--', key: 'plate' },
        { label: '时 间:', value: '--', key: 'timeBegin' },
        { label: '地 址:', value: '--', key: 'address' },
      ],
      alarmsCapsize: [
        { label: '车牌号:', value: '--', key: 'plate' },
        { label: '车 组:', value: '--', key: 'groupName' },
        { label: '告警类型:', value: '--', key: 'alarmDefineAlarmName' },
        //   { label: '状态:', value: '--', key: 'isAcc' },
        { label: '告警内容:', value: '--', key: 'msg' },
        { label: '时 间:', value: '--', key: 'alarmTime' },
        { label: '地 址:', value: '--', key: 'address' },
      ],
      alarmImg: require("@/assets/screen/alarm.png"),
      knowImg: require("@/assets/screen/know.png"),
      capsizeAlarmTitle: '紧急报警',
      isConcealed: false,
      capsizeRemindCount: 1
    };
  },
  mounted() {
   if (hasPerms("M01")) {
    this.getAlarmList();
    this.getCapsizeAlarm();
    // this.intervalId = setInterval(async () => {
    //   await this.getAlarmList();
    // }, 1000 * 8);
    this.handlePolling();
   }
  },
  beforeDestroy() {
    // clearInterval(this.intervalId);
    this.intervalId ? clearInterval(this.intervalId) : '';
  },
  watch: {
    'tableCapsizePage.pageNumber': {
      handler(v) {
        if (this.dataCapsizeList && this.dataCapsizeList.length && this.dataCapsizeList[v - 1].alarmDefineAlarmType == 'mwrew') {
          this.isConcealed = true;
        } else {
          this.isConcealed = false;
        }
        this.dataCapsizeList && this.dataCapsizeList.length ? this.capsizeRemindCount = this.dataCapsizeList[v - 1].remindCount : '';
      },
      deep: true,
      immediate: true
    }
  },
  destroyed() {
    this.intervalId ? clearInterval(this.intervalId) : '';
  },
  deactivated() {
    this.intervalId ? clearInterval(this.intervalId) : '';
  },
  methods: {
    ...mapMutations(["SetPathVehicle"]),
    // 关闭事件
    async onClose() {
      this.visible = false;
    },

    handlePolling() {
      this.intervalId = setTimeout(async () => {
        await this.getAlarmList();
        await this.getCapsizeAlarm();
        clearTimeout(this.intervalId);
        if (this.$store.state.appToken) {
          this.handlePolling();
        }
      }, 1000 * 30);
    },

    // 获取紧急报警列表
    async getAlarmList() {
      try {
        const { rows, flag, msg, total } = await getVehicleOneKey({ userId: -1 });
        if (flag !== 1) return;

        if (rows.length > 0) {
          this.visible = true;
          this.dataList = rows;
          this.tablePage.total = total;
          let points = rows.map((item, index) => ({ lon: item.lonBegin, lat: item.latBegin, tag: index }));
          this.setAddresses(points, 0);
        } else {
          this.onClose();
        }
      } catch (error) {
        console.error('请求错误');
      }
    },

    // 获取倾覆报警列表
    async getCapsizeAlarm() {
      try {
        const { obj, flag, msg, total } = await getCapsizeAlarm({ userId: -1 });
        if (flag !== 1) { this.visibleCapsize = false; return false; };
        if (obj.length > 0) {
          this.visibleCapsize = true;
          let jjArr = obj.filter(val => val.alarmLevel == 1);
          //  console.log(jjInd); capsizeAlarmTitle;
          if (jjArr.length) {
            this.capsizeAlarmTitle = '紧急报警';
            this.dataCapsizeList = jjArr.map(val => {
              val.copywriting = val.Extend && val.Extend.copywriting ? val.Extend.copywriting : '';
              return val;
            });
          } else {
            this.capsizeAlarmTitle = '一般报警';
            let jjArr = obj.filter(val => val.alarmLevel != 1);
            this.dataCapsizeList = jjArr.map(val => {
              val.copywriting = val.Extend && val.Extend.copywriting ? val.Extend.copywriting : '';
              return val;
            });
          }
          this.tableCapsizePage.total = this.dataCapsizeList.length;
          let points = this.dataCapsizeList.map((item, index) => ({ lon: Number(item.lonBegin), lat: Number(item.latBegin), tag: index }));
          this.setCapsizeAddresses(points, 0);
          this.$nextTick(() => {
            if (this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1] && this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].alarmDefineAlarmType == 'mwrew') {
              this.isConcealed = true;
            } else {
              this.isConcealed = false;
            }
            this.capsizeRemindCount = this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].remindCount;
          });
        } else {
          this.visibleCapsize = false;
        }
      } catch (error) {
        console.error('请求错误');
      }
    },

    // 指令下发后刷新数据
    async onCmdSendChange(type) {
      this.alarmiProp.selects = [];
      this.dataList = [];
      this.visible = false;
    },
    async onCapsizeKnowClick() {
      const that = this;
      let { flag } = await alarmWhetherHandle([this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1]]);
      that.getCapsizeAlarm();
      // const cmdParamsBoday = {
      //   isAuto: 0, // 处理方式 0： 人工处理
      //   handleType: 0,
      //   // handleTemplate, // 报警处理模板, 短信有效
      //   userId: this.$store.state.userInfo.userId,
      //   handleContent: '忽略报警',
      //   // handler: handler,
      //   // remark: remark,
      // };
      // let cmdParams = [{
      //   alarmId: this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].alarmId,
      //   groupId: this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].groupId,
      //   vehicleId: this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].vehicleId,
      //   alarmType: this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].alarmType,
      //   extend: {
      //     msgSendAddr: this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].phone,
      //     plate: this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].plate,
      //     alarmAddress: this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].address,
      //     alarmTime: this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1].timeBegin
      //   }
      // }];
      // // 组装下发消息体
      // const cmdData = {
      //   ...cmdParamsBoday,
      //   param: cmdParams
      // };



      // const cmdtype = CmdType.generalAlarmProcess;
      // //  CmdType.generalAlarmProcess;

      // // TODO: 是否需要等待回复
      // cmdWsObj.request.sendCmdAsync(cmdtype, cmdData).then(res => {
      //   that.getCapsizeAlarm();
      // });
    },
    onGoPathClick() {
      this.SetPathVehicle(JSON.stringify(this.dataCapsizeList[this.tableCapsizePage.pageNumber - 1]));
      this.$router.push('/RealTimePositioning');
      this.onCapsizeKnowClick();
    },
    handleCapsizeClose() {
      const that = this;
      this.onCapsizeKnowClick();
      // const cmdParamsBoday = {
      //   isAuto: 0, // 处理方式 0： 人工处理
      //   handleType: 0,
      //   // handleTemplate, // 报警处理模板, 短信有效
      //   userId: this.$store.state.userInfo.userId,
      //   handleContent: '忽略报警',
      //   // handler: handler,
      //   // remark: remark,
      // };
      // let cmdParams = this.dataCapsizeList.map(item => {
      //   return {
      //     alarmId: item.alarmId,
      //     groupId: item.groupId,
      //     vehicleId: item.vehicleId,
      //     alarmType: item.alarmType,
      //     extend: {
      //       msgSendAddr: item.phone,
      //       plate: item.plate,
      //       alarmAddress: item.address,
      //       alarmTime: item.timeBegin
      //     }
      //   };
      // });
      // // 组装下发消息体
      // const cmdData = {
      //   ...cmdParamsBoday,
      //   param: cmdParams
      // };



      // const cmdtype = CmdType.generalAlarmProcess;
      // //  CmdType.generalAlarmProcess;

      // // TODO: 是否需要等待回复
      // cmdWsObj.request.sendCmdAsync(cmdtype, cmdData).then(res => {
      //   that.getCapsizeAlarm();
      // });
    },
    // 指令下发模态框关闭
    closeDialog(model) {
      this.handleVisible = model;
    },
    // 报警处理
    onHandleClick() {
      const { dataList } = this;
      if (!dataList.length) return this.$message.error("请选择报警数据");

      this.alarmiProp.selects = this.dataList;
      this.handleVisible = true;
      this.alarmiProp.alarmParmas = {};
    },

    // 点击分页
    changePage(current) {
      this.$refs.Carousel.setActiveItem(current - 1);
    },
    changeCapsizePage(current) {
      this.tableCapsizePage.pageNumber = current;
      this.$refs.Carousel.setActiveItem(current - 1);
    },
    // 经纬度转地址
    async setAddresses(points, type) {
      const result = await getAddresses(points);

      result.forEach(({ tag, address }) => {
        this.dataList[tag][["address", "address1"][type]] = address;
      });
      if (result.length) this.dataList = [...this.dataList];

    },
    // 经纬度转地址
    async setCapsizeAddresses(points, type) {
      const result = await getAddresses(points);
      result.forEach(({ tag, address }) => {
        this.dataCapsizeList[tag][["address", "address1"][type]] = address;
      });
      if (result.length) this.dataCapsizeList = [...this.dataCapsizeList];

    },
    handleWhetherDisplayed() {
      alarmWhetherDisplayed({
        alarmName: "mwrew"
      }).then(async res => {
        //   await alarmWhetherHandle(this.dataCapsizeList.filter(val => val.alarmDefineAlarmType == 'mwrew'));
        this.isConcealed = false;
        this.getCapsizeAlarm();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-dialog {
    margin: initial !important;
    border-radius: 8px;
    .el-dialog__headerbtn {
      top: 12px;
    }
    .el-dialog__header {
      padding: 10px;
      border-bottom: 1px solid #e8eaec;
      font-weight: 400;
      background: #9e0000;
      font-size: 16px;
      color: #ffffff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .alarm {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
    }

    .el-dialog__footer {
      top: initial;
      padding: 5px;
      text-align: center !important;
    }

    .el-dialog__body {
      padding: initial;
    }
  }
}
::v-deep .el-pagination__jump {
  margin-left: 0;
}
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.exit-icon {
  position: absolute;
  right: 0px;
  top: -2px;
  cursor: pointer;
  width: 20px;
  text-align: center;
}
.bottom {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 35px;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
  & > button {
    margin: 0 3px;
  }
  ::v-deep .el-pagination__total {
    margin-left: 6px;
  }
}
.capsize-bottom {
  justify-content: space-between;
}
.MainPane {
  width: 100%;
  height: auto;
  padding: 0 10px;
  box-sizing: border-box;
}
.itemPane {
  width: 78%;
  display: flex;
  justify-content: flex-start;
  margin: 15px auto;
  & > label {
    margin: 0 6px;
    display: block;
  }
}
.itemPane2 {
  margin: 15px 20px;
  width: 100%;
}
.itemInPane {
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 30px;
}
.pointRed {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px #9e0000 solid;
  & > span {
    display: block;
    width: 6px;
    height: 6px;
    background: #9e0000;
    border-radius: 50%;
    margin: 2px auto;
  }
}
.itemTitle {
  width: 90px;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}
.itemText {
  width: 76%;
}
::v-deep .el-button--danger {
  background: #9e0000;
}
</style>
